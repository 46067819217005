import React, { useEffect, useState, useRef } from 'react';
import './styles.css';

import { getFormattedCurrency } from '../../../../utils';

import IncomeStatementRow from './row';

export default function FinancialStatement({
  title,
  revenues,
  expenses,
  revenuesChildren = [],
  expensesChildren = [],
  shouldSlideIn = false
}) {
  const [displayedRevenues, setDisplayedRevenues] = useState(0);
  const [displayedExpenses, setDisplayedExpenses] = useState(0);
  const [displayedNetProfit, setDisplayedNetProfit] = useState(0);

  const prevRevenuesRef = useRef(0);
  const prevExpensesRef = useRef(0);

  useEffect(() => {
    updateScoreWithDelay(setDisplayedRevenues, displayedRevenues, revenues);

    updateScoreWithDelay(setDisplayedExpenses, displayedExpenses, expenses);

    const netProfit = revenues + expenses;

    if (expenses <= 0) {
      updateScoreWithDelay(setDisplayedNetProfit, displayedNetProfit, netProfit);
    } else {
      const displayedNetProfitValue = revenues - expenses;
      updateScoreWithDelay(setDisplayedNetProfit, displayedNetProfit, displayedNetProfitValue);
    }

    prevRevenuesRef.current = revenues;
    prevExpensesRef.current = expenses;
  }, [revenues, expenses]);

  const updateScoreWithDelay = (setState, currentScore, endScore) => {
    if (currentScore !== endScore) {
      const duration = 1500;
      const steps = 20;
      const stepTime = duration / steps;
      let currentStep = 0;

      const totalChange = endScore - currentScore;
      const changePerStep = totalChange / steps;

      const interval = setInterval(() => {
        currentStep++;
        const newScore = currentScore + changePerStep * currentStep;
        setState(newScore);

        if (currentStep === steps) {
          clearInterval(interval);
          setState(endScore);
        }
      }, stepTime);

      return () => clearInterval(interval);
    }
  };

  const isNegativeRevenues = displayedRevenues < 0;
  const isNegativeExpenses = displayedExpenses < 0;
  const isNegativeProfit = displayedNetProfit < 0;

  return (
    <div className={`transactions-income-statement ${shouldSlideIn ? 'should-slide-in' : ''}`}>
      <h2>{title}</h2>
      <ul className="income-statement-items">
        <li>
          <IncomeStatementRow
            name="Revenues"
            value={getFormattedCurrency(displayedRevenues)}
            isNegative={isNegativeRevenues}
            hasChildren={revenuesChildren.length > 0}
          />
          {
            revenuesChildren.length > 0 &&
              revenuesChildren.map((revenuesChild, index) => (
                <IncomeStatementRow
                  key={index}
                  name={revenuesChild.name}
                  value={getFormattedCurrency(revenuesChild.value)}
                  isNegative={revenuesChild.value < 0}
                  isChild={true}
                />
              ))
          }
        </li>
        <li>
          <IncomeStatementRow
            name="Expenses"
            value={getFormattedCurrency(displayedExpenses)}
            isNegative={isNegativeExpenses}
            hasChildren={expensesChildren.length > 0}
          />
          {
            expensesChildren.length > 0 &&
            expensesChildren.map((expensesChild, index) => (
                <IncomeStatementRow
                  key={index}
                  name={expensesChild.name}
                  value={getFormattedCurrency(expensesChild.value)}
                  isNegative={expensesChild.value < 0}
                  isChild={true}
                />
              ))
          }
        </li>
        <li className="net-profits">
          <IncomeStatementRow
            name="Net Profit"
            value={getFormattedCurrency(displayedNetProfit)}
            isNegative={isNegativeProfit}
          />
        </li>
      </ul>
    </div>
  );
}
