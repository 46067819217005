import PopupMessages from '../../introGameLayout/popupMessages';
import './styles.css';

const emmaPopupMessages = [
  {
    name: 'Emma',
    text: 'Yay! Thanks for prepping my financials! I now have a clearer view of my profits!',
    alignment: 'top'
  }
];

export default function EmmaMessageResults({ onViewResultsClick }) {
  return (
    <div className="emma-message-results-container">
      <PopupMessages
        messages={emmaPopupMessages}
        buttonText="View Results"
        onButtonClick={onViewResultsClick}
        messageSize="small"
      />
    </div>
  );
}
