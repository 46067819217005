import { useLocation } from 'react-router-dom';

export function getFormattedTime(totalSeconds) {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formattedHours = hours > 0 ? String(hours).padStart(2, '0') + ':' : '';
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
}

export const getHighestActiveGame = (games) => {
  const activeGames = games.filter(game => game.is_active);
  
  if (activeGames.length > 0) {
    const highestActiveGame = activeGames.reduce((highestGame, currentGame) => {
      return currentGame.id > highestGame.id ? currentGame : highestGame;
    }, activeGames[0]);

    return highestActiveGame;
  } else {
    return null;
  }
}

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const sortAndSelectTop = (array, sortOrder, isRandomOrder) => {
  let sortedArray = [...array].sort((a, b) => 
    sortOrder === 'asc' ? a.score - b.score : b.score - a.score
  );

  return isRandomOrder ? shuffleArray(sortedArray) : sortedArray;
}

export const getFormattedCurrency = (amount) => {
  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })
}

export const fadeOutSound = (audio, duration) => {
  const stepTime = 50;
  const steps = duration / stepTime;
  const volumeStep = audio.volume / steps;

  const fadeInterval = setInterval(() => {
    if (audio.volume > volumeStep) {
      audio.volume -= volumeStep;
    } else {
      clearInterval(fadeInterval);
      audio.pause();
      audio.volume = 1;
    }
  }, stepTime);
};

export const calculateTimeInSeconds = (timeElapsed) => {
  const daysAndTimeComponents = timeElapsed.split(" ");
  let days = 0;
  let timeString = timeElapsed;

  if (daysAndTimeComponents.length > 1) {
    days = parseInt(daysAndTimeComponents[0], 10);
    timeString = daysAndTimeComponents[1];
  }

  const timeComponents = timeString.split(":");
  const hours = +timeComponents[0];
  const minutes = +timeComponents[1];
  const seconds = timeComponents[2] ? +timeComponents[2] : 0;

  return (days * 86400) + (hours * 3600) + (minutes * 60) + seconds;
}

export const getPathSegments = (path) => path.split('/').filter(Boolean);


export const formatScnearioLevelToFilterName = (name) => {
  return name.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
}


export const useIsInstructorContainingPage = () => {
  const location = useLocation();
  return location.pathname.startsWith('/instructor');
}