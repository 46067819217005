import './styles.css';

export default function TableControls({ 
  rowsPerPage,
  onRowsPerPageChange,
  currentPage,
  onCurrentPageChange,
  totalRows,
  onPageChange,
  totalPages
}) {
  return (
    <div className="table-controls">
      <label>
        Rows per page:
        <select value={rowsPerPage} onChange={(e) => { onRowsPerPageChange(Number(e.target.value)); onCurrentPageChange(1); }}>
          <option value={15}>15</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
        </select>
      </label>
      <span>
        {`${(currentPage - 1) * rowsPerPage + 1}-${Math.min(currentPage * rowsPerPage, totalRows)} of ${totalRows}`}
      </span>
      <button className="prev" onClick={() => onPageChange(-1)} disabled={currentPage === 1}></button>
      <button className="next" onClick={() => onPageChange(1)} disabled={currentPage === totalPages}></button>
    </div>
  );
}
