import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import useAuth from '../../auth/useAuth';

import Layout from '../UI/introGameLayout/layout';

import BackgroundImage from './images/debrief-background.jpg';

import { 
  scoresStatsQuery,
  scoresPrinciplesQuery,
  decisionsQuery,
  scenarioLevelsQuery
} from './api';

import {
  popupMessagesPeerBenchmark,
  popupMessagesPrinciplesMastery
} from './data';

import PopupMessages from '../UI/introGameLayout/popupMessages';

import './styles.css';
import Analysis from '../UI/thePrinciples/analysis';

export default function DebriefThePrinciples() {
  const { getUser, getCurrentGameId, getCurrentScenarioId } = useAuth();

  const user = getUser();
  const username = user?.alias_name 
    ? user?.alias_name 
    : user?.first_name 
      ? user?.first_name
      : user?.username
        ? user?.username 
        : '';
  
  const currentGameId = getCurrentGameId();

  const peerBenchmarkTabTitle = 'Peer Benchmark';
  const principlesMasteryTabTitle = 'Principles Mastery';
  const reviewTabTitle = 'Review';

  const handlePeerBenchmarkMessagesButtonClick = () => {
    setIsPopupMessagesVisible(false);
    setActiveTab(principlesMasteryTabTitle);

    setPopupMessages(popupMessagesPrinciplesMastery);
    setPopupMessagesButtonHandler(() => handlePrinciplesSummaryMessagesButtonClick);

    setTimeout(() => {
      setIsPopupMessagesVisible(true);
      setPopupMessagesButtonText('Summary');
    }, 10);
  };

  const handlePeerBenchmarkTabClick = () => {
    setIsPopupMessagesVisible(false);
    setActiveTab(peerBenchmarkTabTitle);
  };

  const handlePrinciplesSummaryMessagesButtonClick = () => {
    setIsPopupMessagesVisible(false);
    setActiveTab(reviewTabTitle);
    setIsTabsDisabled(false);
  };
  
  const handlePrinciplesMasteryTabClick = () => {
    setActiveTab(principlesMasteryTabTitle);
    // setBars();
  };
  
  const handleReviewTabClick = () => {
    setActiveTab(reviewTabTitle);
  };

  const [isPopupMessagesVisible, setIsPopupMessagesVisible] = useState(true);
  const [popupMessages, setPopupMessages] = useState(popupMessagesPeerBenchmark);
  const [popupMessagesButtonText, setPopupMessagesButtonText] = useState('Next');
  const [popupMessagesButtonHandler, setPopupMessagesButtonHandler] = useState(() => handlePeerBenchmarkMessagesButtonClick);

  const debriefTabs = [
    {
      title: peerBenchmarkTabTitle,
      onClick: handlePeerBenchmarkTabClick
    },
    {
      title: principlesMasteryTabTitle,
      onClick: handlePrinciplesMasteryTabClick
    },
    {
      title: reviewTabTitle,
      onClick: handleReviewTabClick
    },
  ];

  const [activeTab, setActiveTab] = useState(peerBenchmarkTabTitle);
  const [isTabsDisabled, setIsTabsDisabled] = useState(true);
  const [analysisType, setAnalysisType] = useState('bars');

  const [bars, setBars] = useState([]);
  const [questionsDecisions, setQuestionsDecisions] = useState([]);

  const { data: scoresStats } = useQuery(scoresStatsQuery({ gameId: currentGameId }));
  const { data: scoresPrinciples } = useQuery(scoresPrinciplesQuery({ userId: user?.id, gameId: currentGameId }));
  const { data: decisions } = useQuery(decisionsQuery({ userId: user?.id, gameId: currentGameId }));
  const { data: scenarioLevels } = useQuery(scenarioLevelsQuery(getCurrentScenarioId()));

  const formatAnalysisData = () => {
    if(scoresStats && scoresPrinciples && scenarioLevels && decisions) {
      if(activeTab === 'Peer Benchmark') {
        const data = [
          { title: username, score: Math.round(scoresStats[0].user_points), color: '#F9C151' },
          { title: 'Highest', score: Math.round(scoresStats[0].highest_points) },
          { title: 'Mean', score: Math.round(scoresStats[0].average_points) },
          { title: 'Median', score: Math.round(scoresStats[0].median_points) },
        ];
        setBars(data);
        setAnalysisType('bars');
      } else if (activeTab === 'Principles Mastery') {
        const data = scoresPrinciples.map(scoresPrinciple => {
          const title = scoresPrinciple.principle_name;
          const color = title === 'Going Concern' || title === 'Monetary' || title === 'Periodicity' || title === 'Economic Business Entity'
            ? '#FE7474'
            : title === 'Materiality' || title === 'Cost Benefit' || title === 'Conservatism' || title === 'Industry Practice'
              ? '#5B94FF'
              : '#52C5CD';

          return {
            title,
            score: scoresPrinciple.total_points,
            color
          }
        });
        setBars(data);
        setAnalysisType('bars');
      } else if (activeTab === 'Review') {
        setAnalysisType('decisions');
      }
    }
  }

  useEffect(() => {
    if(decisions && scenarioLevels) {
      const scenarioLevel = scenarioLevels.find(level => level.level === 1);
      const sortedTransactions = scenarioLevel.transactions.sort((a, b) => a.order - b.order);

      const questionsDecisionsData = sortedTransactions.map(sortedTransaction => {
        const firstRelatedDecision = decisions.find(decision => decision.question === sortedTransaction.id);
        const activity = sortedTransaction.activity?.[0].name;
        
        let textColor, backgroundColor;

        switch (activity) {
          case 'Going Concern':
          case 'Monetary':
          case 'Periodicity':
          case 'Economic Business Entity':
            textColor = '#EC5E59';
            backgroundColor = '#FAEDED';
            break;
          case 'Materiality':
          case 'Cost Benefit':
          case 'Conservatism':
          case 'Industry Practice':
            textColor = '#5C91F5';
            backgroundColor = '#EBF2FF';
            break;
          default:
            textColor = '#55B4BC';
            backgroundColor = '#ECF6F7';
            break;
        }
        
        return {
          title: `Q${sortedTransaction.order}`,
          isCorrect: firstRelatedDecision?.is_correct,
          viewLink: `/the-principles/level/1/?transaction=${sortedTransaction.order}&status=${firstRelatedDecision?.is_correct ? 'correct' : 'incorrect'}&answer=${firstRelatedDecision?.selected_option}`,
          activity,
          textColor,
          backgroundColor
        };
      });
      setQuestionsDecisions(questionsDecisionsData);
    }
  }, [decisions, scenarioLevels]);

  useEffect(() => {
    formatAnalysisData();
  }, [username, activeTab, scoresStats, scoresPrinciples]);

  return(
    <Layout
      backgroundImage={BackgroundImage}
      isBackgroundBlurred={false}
      isHeaderVisible={true}
      isFooterVisible={false}
    >
      {
        popupMessages.length &&
          <div className={`debrief-popup-messages-container ${isPopupMessagesVisible ? '' : 'hidden'}`}>
            <PopupMessages
              messages={popupMessages}
              buttonText={popupMessagesButtonText}
              onButtonClick={popupMessagesButtonHandler}
            />
          </div>
      }

      <Analysis
        tabs={debriefTabs}
        activeTab={activeTab}
        isTabsDisabled={isTabsDisabled}
        bars={bars}
        analysisType={analysisType}
        questionsDecisions={questionsDecisions}
      />
    </Layout>
  );
}
