import { ItemScore } from './itemScore';

import './styles.css';

export const Item = ({ 
  id,
  name,
  score,
  maxScore,
  formattedScore,
  scoreIndicator,
  showFormattedScore,
  backgroundColor,
  lines
}) => {
  const hasValidLines = lines?.some(line => line.score);
  const hasValidScore = !!score;

  if (!hasValidLines && !hasValidScore) return null;

  return (
    <li>
      {name && <span className="user-name">{name}</span>}
      <span className="user-score-wrapper">
        {hasValidLines ? (
          <ul className="user-score-lines">
            {lines.map((line, index) => 
              line.score ? (
                <li key={index}>
                  <ItemScore
                    score={line.score}
                    maxScore={maxScore}
                    formattedScore={line.formattedScore}
                    scoreIndicator={scoreIndicator}
                    showFormattedScore={showFormattedScore}
                    backgroundColor={line.backgroundColor}
                  />
                </li>
              ) : null
            )}
          </ul>
        ) : (
          <ItemScore 
            id={id}
            name={name}
            score={score}
            maxScore={maxScore}
            formattedScore={formattedScore}
            scoreIndicator={scoreIndicator}
            showFormattedScore={showFormattedScore}
            backgroundColor={backgroundColor}
          />
        )}
      </span>
    </li>
  );
};