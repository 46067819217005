
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { 
  gamesQuery,
  getCourseMembers,
  getClassificationScores,
  getNormalScores,
  getPrinciplesScores,
  getBalancedEquationScores
} from '../api';

import './styles.css';

import { sortAndSelectTop } from '../../../../utils';
import SubPagesLayout from '../../../UI/layout/subPages';

import { navigation } from '../data';
import { LeaderboardOverall } from '../../../UI/instructor/leaderboard/overall';

export default function InstructorInsightsPoints() {
  const navigate = useNavigate();

  const [courseId, setCourseId] = useState(0);

  const [sort, setSort] = useState('asc');
  const [isRandomOrder, setIsRandomOrder] = useState(true);

  const [filterBy, setFilterBy] = useState('Classification');
  const [data, setData] = useState(null);

  const gamesResponse = useQuery(gamesQuery()) || {};
  const games = gamesResponse.data;

  useEffect(() => {
    if (games) {
      let game = games.find(game => game.game_type.includes('Account Classification'));
  
      if (!game) {
        game = games[0];
      }
  
      if (game) {
        setCourseId(game.course);
      }
    }
  }, [games]);  

  const { data: classificationScores } = useQuery({
    queryKey: ['classificationScoresPoints'],
    queryFn: async () => getClassificationScores(),
    retry: false,
    refetchOnWindowFocus: false
  });

  const { data: normalScores } = useQuery({
    queryKey: ['normalScoresPoints'],
    queryFn: async () => getNormalScores(),
    retry: false,
    refetchOnWindowFocus: false
  });
  
  const { data: principlesScores } = useQuery({
    queryKey: ['principlesScoresPoints'],
    queryFn: async () => getPrinciplesScores(),
    retry: false,
    refetchOnWindowFocus: false
  });
  
  const { data: balancedEquationScores } = useQuery({
    queryKey: ['balancedEquationScoresPoints'],
    queryFn: async () => getBalancedEquationScores(),
    retry: false,
    refetchOnWindowFocus: false
  });
  
  const { data: courseMembers } = useQuery({
    queryKey: ['courseMembersPoints'],
    queryFn: async () => getCourseMembers(courseId),
    enabled: !!courseId,
    retry: false,
    refetchOnWindowFocus: false
  });

  const filterByOptions = [...new Set(games?.map(game => {
    switch (game.game_type) {
      case 'Account Classification':
        return 'Classification';
      case 'Normal Balance':
        return 'Normal';
      case 'Balanced Equation':
        return 'Double Entry';
      default:
        return game.game_type;
    }
  }))].sort((a, b) => {
    const order = ['Classification', 'Normal', 'The Principles', 'Double Entry'];
    return order.indexOf(a) - order.indexOf(b);
  });
    
  const handleSortClick = () => {
    setIsRandomOrder(false);
    setSort(prevSort => (prevSort === 'desc' ? 'asc' : 'desc'));
  };

  const formatLeaderboardData = () => {  
    let wholeLeaderboardData = [];
    let scores;

    if(filterBy === 'Classification') {
      scores = classificationScores;
    } else if(filterBy === 'Normal') {
      scores = normalScores;
    } else if(filterBy === 'Double Entry') {
      scores = balancedEquationScores;
    } else if(filterBy === 'The Principles') {
      scores = principlesScores;
    }
    
    wholeLeaderboardData = courseMembers.map(courseMember => {
      const userScores = scores.filter(score => score.user === courseMember.user_id);
      const uniqueSessions = new Set(userScores.map(score => score.game_session)).size;
      const totalPoints = userScores.reduce((sum, score) => sum + score.total_points, 0);
      const score = uniqueSessions > 0 ? totalPoints / uniqueSessions : 0;
      
      return {
        name: courseMember.name,
        score,
        formattedScore: score
      }
    });

    const sortedLeaderboardData = sortAndSelectTop(wholeLeaderboardData, sort, isRandomOrder)
      .map((item, index) => ({
        ...item,
        backgroundColor: index < 3 ? '#5C91F5' : index < 6 ? '#A98DF6' : undefined
      }));

    setData(sortedLeaderboardData);
  }

  useEffect(() => {
    if(classificationScores && normalScores && balancedEquationScores && courseMembers) {
      if(filterByOptions[0] !== 'Classification') {
        setFilterBy(filterByOptions[0]);
      }

      formatLeaderboardData();
    }
  }, [classificationScores, normalScores, balancedEquationScores, courseMembers]);

  useEffect(() => {
    if(classificationScores && normalScores && balancedEquationScores && courseMembers) {
      formatLeaderboardData();
    }
  }, [filterBy, sort]);

  const handleNextButtonClick = () => {
    navigate('/instructor/insights/leaderboard/accuracy');
  }

  const handleFilterClick = (selectedFilterBy) => {
    setIsRandomOrder(true);
    setSort('asc');
    setFilterBy(selectedFilterBy);
  }
  
  const maxScore = data ? data.reduce((max, item) => (item.score > max ? item.score : max), -Infinity) : 0;

  return (
    <SubPagesLayout
      navigation={navigation}
      contentClassName="insights-content-container"
      onSecondButtonClick={handleNextButtonClick}
    >
      <h1>Insights | Leaderboard</h1>
      <h2>Points</h2>
      <p>Points are driven by how quickly students make correct decisions.</p>
        <LeaderboardOverall
          title="Points"
          data={data}
          filterBy={filterBy}
          filterByOptions={filterByOptions}
          onFilterByClick={handleFilterClick}
          maxScore={maxScore}
          sort={sort}
          onSortClick={handleSortClick}
        />
    </SubPagesLayout>
  );
}
