import './styles.css';

export default function Pagination({ transactions, activeTransaction }) {
  return (
    // <ul className="transactions-decisions-pagination">
    //   {
    //     transactions.map((transaction, index) => (
    //       <li key={index} className={activeTransaction === index ? 'active' : ''}></li>
    //     ))
    //   }
    // </ul>
    <div className="transactions-decisions-active-page">
      <span>{activeTransaction + 1}</span>
    </div>
  );
}
