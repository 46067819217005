import { useState, useEffect, useMemo } from 'react';
import { useDrop } from 'react-dnd';

import Hint from '../../hint';

import { getFormattedCurrency } from '../../../../utils';

import './styles.css';

import SignPlus from './images/icon-plus.svg';
import DropAreaList from './dropAreaList';

export default function DropArea({ 
  option, 
  value,
  isSecondDropArea = false,
  onChangeItemDrop,
  onCoreItemDrop,
  onDropAreaChange,
  resetKey,
  incorrectDecisions,
  onSecondDropAreaValueDrop,
  onSecondDropAreaCoreItemDrop,
  isItemsDisabled
}) {
  const [droppedItems, setDroppedItems] = useState([]);

  useEffect(() => {
    setDroppedItems([]);
  }, [resetKey]);

  const currentDropAreaIncorrectDecisions = useMemo(() => 
    incorrectDecisions?.filter(incorrectDecision => incorrectDecision.isSecondDropArea === isSecondDropArea) || [], 
    [incorrectDecisions, isSecondDropArea]
  );
  
  useEffect(() => {
    if(currentDropAreaIncorrectDecisions.length) {
      setDroppedItems(prevItems => {
        return prevItems.map(item => {
          const isIncorrect = currentDropAreaIncorrectDecisions.some(incorrectDecision => incorrectDecision.id === item.id);
          return isIncorrect ? { ...item, isIncorrect: true } : item;
        });
      });
    }
  }, [currentDropAreaIncorrectDecisions.length]);

  const handleItemDrop = (item) => {
    setDroppedItems(prevItems => {
      const boxContainsDebit = prevItems.some(droppedItem => droppedItem.name === 'Debit');
      const boxContainsCredit = prevItems.some(droppedItem => droppedItem.name === 'Credit');
      const boxContainsIncrease = prevItems.some(droppedItem => droppedItem.description === 'Increase');
      const boxContainsDecrease = prevItems.some(droppedItem => droppedItem.description === 'Decrease');

      const isAssetCategory = option.name === 'Assets';
      const isLiabilityOrEquityCategory = option.name === 'Liabilities' || option.name === 'Equity';

      if (
        (isAssetCategory && ((item.description === 'Increase' && boxContainsCredit) || (item.description === 'Decrease' && boxContainsDebit))) ||
        (isLiabilityOrEquityCategory && ((item.description === 'Increase' && boxContainsDebit) || (item.description === 'Decrease' && boxContainsCredit))) ||
        (isAssetCategory && ((item.name === 'Credit' && boxContainsIncrease) || (item.name === 'Debit' && boxContainsDecrease))) ||
        (isLiabilityOrEquityCategory && ((item.name === 'Debit' && boxContainsIncrease) || (item.name === 'Credit' && boxContainsDecrease)))
      ) {
        return prevItems;
      }
      
      const itemTypeExists = prevItems.some(droppedItem => droppedItem.type === item.type);
      let updatedDroppedItems;
      let updatedValue = 0;
  
      if (itemTypeExists) {
        updatedDroppedItems = prevItems.map(prevItem => {
          if (prevItem.type === item.type) {
            if(item.type === 'change') {
              if(onSecondDropAreaValueDrop) {
                onSecondDropAreaValueDrop(item.description);
              }
              
              if (prevItem.description !== item.description) {
                updatedValue = calculateUpdatedValue(item.description, value * 2);
                onChangeItemDrop(option.name, updatedValue);

                const droppedCoreItem = prevItems.find(prevItem => prevItem.type === 'core');
                if(droppedCoreItem) {
                  onCoreItemDrop(option.name, {name: droppedCoreItem.name, value: updatedValue}, 'add');
                }
              } else {
                return prevItem;
              }
            } else if(item.type === 'core') {
              const droppedChangeItem = prevItems.find(prevItem => prevItem.type === 'change');
              let updatedValue;

              if(droppedChangeItem) {
                const oppositeChange = droppedChangeItem.description === 'Increase' ? 'Decrease' : 'Increase';
                updatedValue = calculateUpdatedValue(oppositeChange, value);
                onCoreItemDrop(option.name, {name: prevItem.name, value: updatedValue}, 'add');
              }

              updatedValue = droppedChangeItem ? calculateUpdatedValue(droppedChangeItem.description, value) : 0;
              onCoreItemDrop(option.name, {name: item.name, value: updatedValue}, 'add');

              if(onSecondDropAreaCoreItemDrop) {
                onSecondDropAreaCoreItemDrop(item.name);
              }
            }
            return item;
          }
          return prevItem;
        });
      } else {
        updatedDroppedItems = [...prevItems, item];
        if (item.type === 'change') {
          updatedValue = calculateUpdatedValue(item.description, value);
          onChangeItemDrop(option.name, updatedValue);
          const formattedValue = getFormattedCurrency(value);
          const valueItem = { type: 'value', name: formattedValue, description: formattedValue };
          updatedDroppedItems = [...updatedDroppedItems, valueItem];
          
          if(onSecondDropAreaValueDrop) {
            onSecondDropAreaValueDrop(item.description);
          }

          const droppedCoreItem = prevItems.find(prevItem => prevItem.type === 'core');
          if(droppedCoreItem) {
            updatedValue = calculateUpdatedValue(item.description, value);
            onCoreItemDrop(option.name, {name: droppedCoreItem.name, value: updatedValue}, 'add');
          }
        } else if (item.type === 'core') {
          const droppedChangeItem = prevItems.find(prevItem => prevItem.type === 'change');

          let updatedValue = droppedChangeItem ? calculateUpdatedValue(droppedChangeItem.description, value) : 0;
          onCoreItemDrop(option.name, {name: item.name, value: updatedValue}, 'add');

          if(onSecondDropAreaCoreItemDrop) {
            onSecondDropAreaCoreItemDrop(item.name);
          }
        }
      }
  
      onDropAreaChange({
        id: `${option.id}_${+isSecondDropArea}`,
        optionAccountItemId: option.accountItemId,
        optionName: option.name,
        droppedItemsCount: updatedDroppedItems.length, 
        box: !isSecondDropArea ? 'Top' : 'Bottom',
        changeId: item.type === 'change' ? item.id : null,
        changeAccountItemId: item.type === 'change' ? item.accountItemId : null,
        changeDescription: item.type === 'change' ? item.description.toLowerCase() : null,
        coreId: item.type === 'core' ? item.id : null,
        coreAccountItemId: item.type === 'core' ? item.accountItemId : null,
        coreName: item.type === 'core' ? item.name : null,
        transactionId: item.type === 'transaction' ? item.id : null,
        transactionAccountItemId: item.type === 'transaction' ? item.accountItemId : null,
        transactionName: item.type === 'transaction' ? item.name : null,
      });
      return updatedDroppedItems;
    });

  };
  
  const calculateUpdatedValue = (description, value) => {
    const updatedValue = description === 'Increase' ? value : -value;
    return updatedValue;
  };
  

  const [{ isOver }, drop] = useDrop({
    accept: 'recordOption',
    hover: (item) => {
      const droppedChangeItemName = droppedItems.find(droppedItem => droppedItem.type === 'change')?.description;
      const droppedChangeItemOppositeName = droppedChangeItemName === 'Increase' ? 'Decrease' : 'Increase';
      const droppedCoreItemName = droppedItems.find(droppedItem => droppedItem.type === 'core')?.name;
      const droppedDebitOrCreditItemName = droppedItems.find(droppedItem => droppedItem.type === 'transaction')?.name;

      const isAssetCategory = option.name === 'Assets';
      const isLiabilityOrEquityCategory = option.name === 'Liabilities' || option.name === 'Equity';
      const optionName = option.name;

      const changeItemName = item.description;
      const changeItemOppositeName = changeItemName === 'Increase' ? 'Decrease' : 'Increase';

      const itemName = item.name;

      const boxContainsDebit = droppedDebitOrCreditItemName === 'Debit';
      const boxContainsCredit = droppedDebitOrCreditItemName === 'Credit';
      const boxContainsIncrease = droppedChangeItemName === 'Increase';
      const boxContainsDecrease = droppedChangeItemName === 'Decrease';

      const changeArticle = changeItemName === "Increase" ? "an" : "a";
      const transactionArticle = droppedChangeItemName === "Increase" ? "an" : "a";
        
      const content = item.type === 'change'
        ? (isAssetCategory && ((changeItemName === 'Increase' && boxContainsCredit) || (changeItemName === 'Decrease' && boxContainsDebit))) || (isLiabilityOrEquityCategory && ((changeItemName === 'Increase' && boxContainsDebit) || (changeItemName === 'Decrease' && boxContainsCredit)))
          ? `Contradictory decision. This box already has a ${droppedDebitOrCreditItemName} to ${optionName} (i.e. ${changeItemOppositeName}) decision, therefore ${changeArticle} ${changeItemName} ${optionName} decision is not allowed.`
          : droppedCoreItemName
            ? `${changeItemName} ${droppedCoreItemName}`
            : `${changeItemName} ${optionName}`
        : item.type === 'transaction'
          ? (isAssetCategory && ((itemName === 'Credit' && boxContainsIncrease) || (itemName === 'Debit' && boxContainsDecrease))) || (isLiabilityOrEquityCategory && ((itemName === 'Debit' && boxContainsIncrease) || (itemName === 'Credit' && boxContainsDecrease)))
              ? `Contradictory decision. This box already has ${transactionArticle} ${droppedChangeItemName} to ${optionName} decision, therefore a ${itemName} ${optionName} (i.e. ${droppedChangeItemOppositeName}) decision is not allowed.`
              : droppedCoreItemName
                ? `${itemName} ${droppedCoreItemName}`
                : `${itemName} ${optionName}`
          : droppedDebitOrCreditItemName
            ? `${droppedDebitOrCreditItemName} ${itemName}`
            : droppedChangeItemName
              ? `${droppedChangeItemName} ${itemName}`
              : null;
      
      setHintContent(content);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    }),
    drop: handleItemDrop
  });

  const [isHintShown, setIsHintShown] = useState(false);
  const [hintContent, setHintContent] = useState('');

  if (isOver && !isHintShown && hintContent) {
    setIsHintShown(true);
  } else if (!isOver && isHintShown) {
    setIsHintShown(false);
  }

  const [secondDropAreaChange, setSecondDropAreaChange] = useState('');

  const handleSecondDropAreaValueDrop = (change) => {
    setSecondDropAreaChange(change);
  }

  const [secondDropAreaBalanceSheetCoreName, setSecondDropAreaBalanceSheetCoreName] = useState('');

  const handleSecondDropAreaBalanceSheetCoreItemDrop = (name) => {
    setSecondDropAreaBalanceSheetCoreName(name);
  }

  const handleItemRemoveFromPrevDropArea = (draggedItem) => {
    const updatedDroppedItems = droppedItems.filter(item => item.id !== draggedItem.id);
  
    if (draggedItem.type === 'change') {
      const valueItemIndex = updatedDroppedItems.findIndex(item => item.type === 'value');
      if (valueItemIndex !== -1) {
        updatedDroppedItems.splice(valueItemIndex, 1);
      }
  
      let oppositeChange = draggedItem.description === 'Increase' ? 'Decrease' : 'Increase';

      let updatedValue = calculateUpdatedValue(oppositeChange, value);
      if(secondDropAreaChange) {
        oppositeChange = secondDropAreaChange === 'Increase' ? 'Decrease' : 'Increase';
        const secondDropAreaValue = calculateUpdatedValue(oppositeChange, value);
        updatedValue += secondDropAreaValue;
        setSecondDropAreaChange('');
      }

      onChangeItemDrop(option.name, updatedValue);

      const droppedCoreItem = droppedItems.find(droppedItem => droppedItem.type === 'core');
      if(droppedCoreItem) {
        oppositeChange = draggedItem.description === 'Increase' ? 'Decrease' : 'Increase';
        const updatedValue = calculateUpdatedValue(oppositeChange, value);
        onCoreItemDrop(option.name, {name: droppedCoreItem.name, value: updatedValue}, 'add');
      }
    } else if(draggedItem.type === 'core') {
      const droppedChangeItem = droppedItems.find(droppedItem => droppedItem.type === 'change');
      let updatedValue = 0;
      if(droppedChangeItem) {
        const oppositeChange = droppedChangeItem.description === 'Increase' ? 'Decrease' : 'Increase';
        updatedValue = calculateUpdatedValue(oppositeChange, value);
      }
      onCoreItemDrop(option.name, {name: draggedItem.name, value: updatedValue}, 'add', true);
    }
  
    onDropAreaChange({
      id: `${option.id}_${+isSecondDropArea}`,
      droppedItemsCount: updatedDroppedItems.length,
      ...(draggedItem.type === 'change' && { changeId: 0 }),
      ...(draggedItem.type === 'core' && { coreId: 0 }),
      ...(draggedItem.type === 'transaction' && { transactionId: 0 }),
    });

    if(updatedDroppedItems.length === 0 && !isSecondDropArea) {
      onDropAreaChange({
        id: `${option.id}_1`,
        droppedItemsCount: 0
      });

      if(secondDropAreaBalanceSheetCoreName) {
        const oppositeChange = secondDropAreaChange === 'Increase' ? 'Decrease' : 'Increase';
        const updatedValue = calculateUpdatedValue(oppositeChange, value);
        onCoreItemDrop(option.name, {name: secondDropAreaBalanceSheetCoreName, value: updatedValue}, 'add');
      }
    }

    setDroppedItems(updatedDroppedItems);
  };

  const hasDroppedItems = droppedItems.length > 0;

  return (
    <div className="transaction-record-drop-area-wrapper">
      <div ref={drop} className={`transaction-record-drop-area ${!hasDroppedItems ? 'empty' : ''}`}>
        {
          hasDroppedItems &&
            <DropAreaList 
              items={droppedItems}
              onItemsDropped={handleItemRemoveFromPrevDropArea}
              isItemsDisabled={isItemsDisabled}
            />
        }
        {
          !hasDroppedItems &&
            <>
              <img src={SignPlus} alt="Plus" />
              <span>add a record</span>
            </>
        }
        {
          isHintShown &&
            <Hint 
              content={hintContent}
              side="left"
            />
        }
      </div>
      {
        hasDroppedItems && !isSecondDropArea && 
          <DropArea
            option={option}
            value={value}
            isSecondDropArea={true}
            onChangeItemDrop={onChangeItemDrop}
            onCoreItemDrop={onCoreItemDrop}
            onDropAreaChange={onDropAreaChange}
            incorrectDecisions={incorrectDecisions}
            onSecondDropAreaValueDrop={handleSecondDropAreaValueDrop}
            onSecondDropAreaCoreItemDrop={handleSecondDropAreaBalanceSheetCoreItemDrop}
            isItemsDisabled={isItemsDisabled}
          />
      }
    </div>
  );
}
