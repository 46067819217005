import './styles.css';

export default function AnswerOptions({ 
  options,
  selectedOption,
  answerStatus,
  optionsDisabled,
  onOptionClick
}) {

  return (
    <div className="transactions-answer-options">
      {options.map((option, index) => (
        <button 
          key={index}
          className={`${(option === selectedOption && answerStatus) ? `active ${answerStatus}` : ''} ${(option === selectedOption) ? `active` : ''}`}
          onClick={() => {
            if (!optionsDisabled) {
              onOptionClick(option);
            }
          }}
        >
          {option}
        </button>
      ))}
    </div>
  );
}
