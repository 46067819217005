import axiosClient from '../../../api/axiosClient.js';
import { fetchPageData } from '../../../api/utils.js';

async function getGames() {
  return fetchPageData(`/api/games/games/`);
}

export const gamesQuery = () => {
  return {
    queryKey: ['games'],
    queryFn: async () => getGames(),
    refetchOnWindowFocus: false
  };
};

export async function getClassificationScores(gameId) {
  return fetchPageData(`/api/classification/scores/${gameId ? `?game=${gameId}` : ''}`);
}

export async function getNormalScores(gameId) {
  return fetchPageData(`/api/normal/scores/${gameId ? `?game=${gameId}` : ''}`);
}

export async function getPrinciplesScores(gameId) {
  return fetchPageData(`/api/principles/scores/${gameId ? `?game=${gameId}` : ''}`);
}

export async function getBalancedEquationScores(gameId) {
  return fetchPageData(`/api/equation/scores/${gameId ? `?game=${gameId}` : ''}`);
}

export async function getClassificationScoresByQuestion(gameId) {
  return fetchPageData(`/api/classification/scores/by-question/${gameId ? `?game=${gameId}` : ''}`);
}

export async function getNormalScoresByQuestion(gameId) {
  return fetchPageData(`/api/normal/scores/by-question/${gameId ? `?game=${gameId}` : ''}`);
}

export async function getPrinciplesScoresByQuestion(gameId) {
  const response = await axiosClient.get(`/api/principles/scores/principles/${gameId ? `?game=${gameId}` : ''}`);
  return response.data;
}

export async function getBalancedEquationScoresByQuestion(gameId) {
  return fetchPageData(`/api/equation/scores/by-question/${gameId ? `?game=${gameId}` : ''}`);
}

export async function getEquationScoresInsights(gameId) {
  return fetchPageData(`/api/equation/scores/insights/${gameId ? `?game=${gameId}` : ''}`);
}

export async function getClassificationScoresInsights(gameId) {
  return fetchPageData(`/api/classification/scores/insights/${gameId ? `?game=${gameId}` : ''}`);
}

export async function getNormalScoresInsights(gameId) {
  return fetchPageData(`/api/normal/scores/insights/${gameId ? `?game=${gameId}` : ''}`);
}

export async function getScenarioLevels(scenarioId) {
  return fetchPageData(`/api/games/scenario-levels/?scenarios=${scenarioId}`);
}

export async function getCourseMembers() {
  return fetchPageData(`/api/users/course-members/`);
}

export const courseMembersQuery = () => {
  return {
    queryKey: ['courseMembers'],
    queryFn: async () => getCourseMembers(),
    refetchOnWindowFocus: false
  };
};

export async function getClassificationDecisionsInsights({gameId, levelNumber}) {
  const response = await axiosClient.get(`/api/classification/decisions/insights/${gameId}/${levelNumber}`);
  return response.data;
}

export async function getBalancedEquationDecisionsInsights({gameId, levelNumber}) {
  const response = await axiosClient.get(`/api/equation/decisions/insights/${gameId}/${levelNumber}`);
  return response.data;
}

export async function getItems() {
  return fetchPageData(`/api/games/items/`);
}

export async function getGamesTransactions() {
  return fetchPageData(`/api/games/transactions/`);
}


export async function getNormalDecisionsInsights({gameId, levelNumber}) {
  const response = await axiosClient.get(`/api/normal/decisions/insights/${gameId}/${levelNumber}`);
  return response.data;
}

export async function getGamesSessions() {
  return fetchPageData(`/api/games/sessions/`);
}

export const gamesSessionsQuery = () => {
  return {
    queryKey: ['gamesSessionsScores'],
    queryFn: async () => getGamesSessions(),
    refetchOnWindowFocus: false
  };
};