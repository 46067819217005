import Avatar from '../avatar';
import Icon from './icon';
import { Link } from 'react-router-dom';

import './styles.css';
import React from 'react';

export default function Explanation({
  isAvatarVisible,
  name,
  status,
  content,
  shouldContentBeParsed = true
}) {
  const parseContent = (text) => {
    const segments = text?.split(/(""[^"]*""|"[^"]*")/);
    let parsedText = [];

    for (let i = 0; i < segments?.length; i++) {
      const segment = segments[i];

      if (segment.startsWith('""') && segment.endsWith('""')) {
        // This segment is wrapped in double double quotes
        const innerContent = segment.slice(2, -2);
        parsedText.push(<button key={i}>{innerContent}</button>);
      } else if (segment.startsWith('"') && segment.endsWith('"')) {
        // This segment is wrapped in double quotes
        const innerContent = segment.slice(1, -1);
        parsedText.push(<b key={i}>{innerContent}</b>);
      } else {
        // Regular text segment, add as-is
        parsedText.push(segment);
      }
    }

    return parsedText;
  };  
  
  return (
    <div className={`decision-explanation-container ${status}`}>
      {isAvatarVisible && (
        <Avatar
          name={name}
          showName={true}
          isImageWithBorder={true}
        />
      )}
      {status && (
        <div className="decision-explanation-title-container">
          <Icon status={status} />
          <span>{status.charAt(0).toUpperCase() + status.slice(1)}</span>
        </div>
      )}
      <div className="decision-explanation-content">
        {
          shouldContentBeParsed && 
            <p>{parseContent(content)}</p>
        }
        {
          !shouldContentBeParsed && content
        }
      </div>
      <p className="decision-explanation-content">
        
        
      </p>
    </div>
  );
}


