
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import {
  gamesQuery,
  getScenarioLevels,
  getClassificationDecisionsInsights,
  getItems
} from '../api';

import { navigation } from '../data';
import InstructorTable from '../../../UI/instructor/table';
import SubPagesLayout from '../../../UI/layout/subPages';

export default function InstructorInsightsClassification() {
  const navigate = useNavigate();

  const params = useParams();
  const levelNumber = Number(params.levelNumber) || 1;

  const [gameId, setGameId] = useState(0);
  const [scenarioId, setScenarioId] = useState(0);

  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);

  const gamesResponse = useQuery(gamesQuery()) || {};
  const games = gamesResponse.data;

  useEffect(() => {
    if (games) {
      const currentTypeGames = games.filter(game => game.game_type === 'Account Classification');
      const game = currentTypeGames
        .reduce((earliest, current) => 
            new Date(current.start_time) < new Date(earliest.start_time) ? current : earliest, 
            currentTypeGames[0]
        );

      if(game) {
        setGameId(game.id);
        setScenarioId(game.scenario);
      }
    }
  }, [games]);

  const { data: scenarioLevels, isSuccess: isScenarioLevelsSuccess } = useQuery({
    queryKey: ['classificationScenarioLevels'],
    queryFn: async () => getScenarioLevels(scenarioId),
    enabled: !!scenarioId,
    retry: false,
    refetchOnWindowFocus: false
  });

  const { data: decisionsInsights, isSuccess: isDecisionsInstingsSuccess } = useQuery({
    queryKey: [`classificationDecisionsInsightsLevel${levelNumber}`],
    queryFn: async () => getClassificationDecisionsInsights({gameId, levelNumber}),
    enabled: !!gameId,
    retry: false,
    refetchOnWindowFocus: false
  });

  const { data: items, isSuccess: isItemsSuccess } = useQuery({
    queryKey: ['items'],
    queryFn: async () => getItems(),
    retry: false,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (scenarioLevels?.length > 0 && decisionsInsights?.length > 0 && items?.length > 0) {
      const currentScenarioLevel = scenarioLevels.find(scenarioLevel => scenarioLevel.level === levelNumber);
      const headers = currentScenarioLevel?.options
        .filter(option => {
          if (levelNumber > 1) {
            return option.account_item.parents && option.account_item.parents.length > 0;
          }
          return true;
        })
        .sort((a, b) => a.order - b.order)
        .map(option => option.account_item.name);
      setTableHeaders(headers);

      const data = currentScenarioLevel.questions
        .sort((a, b) => a.order - b.order)
        .map(question => {
          const row = {
            question: question.account_item.name,
          };

          const insight = decisionsInsights.find(insight => insight.question === row.question);
          let totalValue = 0;
          insight?.options.forEach(insightsOption => {
            const optionItem = items.find(item => item.name === insightsOption.option);
            const questionItem = items.find(item => item.name === row.question);
            const isParentMatch = questionItem.parents.includes(optionItem.id);

            row[insightsOption.option] = {
              value: insightsOption.count_users,
              isParentMatch,
            };

            totalValue += insightsOption.count_users;
          });

          insight?.options.forEach(insightsOption => {
            const itemData = row[insightsOption.option];
            const threshold = Math.min(10, totalValue * 0.2);
            
            if (itemData.isParentMatch) {
              itemData.background = '#D1E9D6';
            } else if (itemData.value && itemData.value >= threshold) {
              itemData.background = '#FD9595';
            } else {
              itemData.background = '#FFFFFF';
            }
          });
          
          return row;
        });
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [scenarioLevels, decisionsInsights, levelNumber, items]);

  const handlePrevButtonClick = () => {
    let redirectUrl = levelNumber === 1 
      ? '/instructor/insights/decision-insights/double-entry' 
      : `/instructor/insights/decision-insights/classification/${levelNumber - 1}`;
    navigate(redirectUrl);
  }

  const handleNextButtonClick = () => {
    let redirectUrl = levelNumber === scenarioLevels?.length 
      ? '/instructor/insights/decision-insights/normal/1' 
      : `/instructor/insights/decision-insights/classification/${levelNumber + 1}`;
    navigate(redirectUrl);
  }
 
  let titleDynamicPart = '';
  switch(levelNumber) {
    case 1:
      titleDynamicPart = 'Sorting the Elements';
      break;
    case 2:
      titleDynamicPart = 'Sorting Common Accounts';
      break;
    case 3:
      titleDynamicPart = 'Sorting Accruals & Deferrals';
      break;
    case 4:
      titleDynamicPart = 'Sorting Accounts for a Real Company (Nike)';
      break;
    default:
      titleDynamicPart = '';
      break;
  }
  const title = `Level ${levelNumber} | ${titleDynamicPart}`;

  return (
    <SubPagesLayout
      navigation={navigation}
      onFirstButtonClick={handlePrevButtonClick}
      onSecondButtonClick={handleNextButtonClick}
      currentPage={levelNumber}
      pagesQuantity={scenarioLevels?.length}
    >
      <h1>Insights | Decisions</h1>
      <h2>Accounts Classification</h2>
      <p>Below is the distribution of students' sorting decisions on their first attempt.</p>
      <InstructorTable 
        title={title}
        tableColumnHeader="Sorting Items"
        tableHeaders={tableHeaders}
        tableData={tableData}
        isDataLoading={!isScenarioLevelsSuccess || !isDecisionsInstingsSuccess || !isItemsSuccess}
      />
    </SubPagesLayout>
  );
}
