export const navigation = [
  {
    title: 'Decision Insights',
    url: '/insights/decision-insights/overall',
    children: [
      {
        title: 'Overall',
        url: '/insights/decision-insights/overall',
      },
      {
        title: 'Classifying & Normal Balances',
        url: '/insights/decision-insights/classifying-normal',
      },
      {
        title: 'Double Entry',
        url: '/insights/decision-insights/double-entry',
      }
    ]
  }
];