import React, { useEffect, useState, useRef } from 'react';
import './styles.css';

import { getFormattedCurrency } from '../../../../utils';

import IncomeStatementRow from './row';

export default function BalanceSheet({
  title,
  assets,
  liabilities,
  equity,
  assetsChildren = [],
  liabilitiesChildren = [],
  equityChildren = [],
  shouldSlideIn = false
}) {
  const [displayedAssets, setDisplayedAssets] = useState(0);
  const [displayedLiabilities, setDisplayedLiabilities] = useState(0);
  const [displayedEquity, setDisplayedEquity] = useState(0);

  const prevAssetsRef = useRef(0);
  const prevLiabilitiesRef = useRef(0);
  const prevEquityRef = useRef(0);

  useEffect(() => {
    updateScoreWithDelay(setDisplayedAssets, displayedAssets, assets);
    updateScoreWithDelay(setDisplayedLiabilities, displayedLiabilities, liabilities);
    updateScoreWithDelay(setDisplayedEquity, displayedEquity, equity);

    prevAssetsRef.current = assets;
    prevLiabilitiesRef.current = liabilities;
    prevEquityRef.current = equity;
  }, [assets, liabilities, equity]);

  const updateScoreWithDelay = (setState, currentScore, endScore) => {
    if (currentScore !== endScore) {
      const duration = 1500;
      const steps = 20;
      const stepTime = duration / steps;
      let currentStep = 0;

      const totalChange = endScore - currentScore;
      const changePerStep = totalChange / steps;

      const interval = setInterval(() => {
        currentStep++;
        const newScore = currentScore + changePerStep * currentStep;
        setState(newScore);

        if (currentStep === steps) {
          clearInterval(interval);
          setState(endScore);
        }
      }, stepTime);

      return () => clearInterval(interval);
    }
  };

  const isAssetsNegative = displayedAssets < 0;
  const isLiabilitiesNegative = displayedLiabilities < 0;
  const isEquityNegative = displayedEquity < 0;

  return (
    <div className={`transactions-income-statement ${shouldSlideIn ? 'should-slide-in' : ''} income-statement-balance-sheet`}>
      <h2>{title}</h2>
      <ul className="income-statement-items">
        <li>
          <IncomeStatementRow
            name="Assets"
            value={getFormattedCurrency(displayedAssets)}
            isNegative={isAssetsNegative}
            hasChildren={assetsChildren.length > 0}
          />
          {
            assetsChildren.length > 0 &&
              assetsChildren.map((assetsChild, index) => (
                <IncomeStatementRow
                  key={index}
                  name={assetsChild.name}
                  value={getFormattedCurrency(assetsChild.value)}
                  isNegative={assetsChild.value < 0}
                  isChild={true}
                />
              ))
          }
        </li>
        <li>
          <IncomeStatementRow
            name="Liabilities"
            value={getFormattedCurrency(displayedLiabilities)}
            isNegative={isLiabilitiesNegative}
            hasChildren={liabilitiesChildren.length > 0}
          />
          {
            liabilitiesChildren.length > 0 &&
              liabilitiesChildren.map((liabilitiesChild, index) => (
                <IncomeStatementRow
                  key={index}
                  name={liabilitiesChild.name}
                  value={getFormattedCurrency(liabilitiesChild.value)}
                  isNegative={liabilitiesChild.value < 0}
                  isChild={true}
                />
              ))
          }
        </li>
        <li>
          <IncomeStatementRow
            name="Equity"
            value={getFormattedCurrency(displayedEquity)}
            isNegative={isEquityNegative}
            hasChildren={equityChildren.length > 0}
          />
          {
            equityChildren.length > 0 &&
              equityChildren.map((equityChild, index) => (
                <IncomeStatementRow
                  key={index}
                  name={equityChild.name}
                  value={getFormattedCurrency(equityChild.value)}
                  isNegative={equityChild.value < 0}
                  isChild={true}
                />
              ))
          }
        </li>
      </ul>
    </div>
  );
}
