import './styles.css';
import { Link } from 'react-router-dom';

export default function Modal({ 
  isModalOverlayVisible,
  onCloseButtonClick
}) {
  
  return (
    <div className={`modal-overlay ${!isModalOverlayVisible ? 'hidden' : ''}`}>
      <div className="modal expense-recognition-modal">
        <button className="close-button" onClick={onCloseButtonClick}></button>
        <h3>Expense Recognition Principles</h3>
        <p>Expense should be recognized when either:</p>
        <ul>
          <li>A company used or consumed goods or services (<Link href="https://corporatefinanceinstitute.com/resources/accounting/matching-principle/#:~:text=The matching principle is an,, quarter, or month)." target="_blank">Matching principles</Link>).</li>
          <li>A company's resource no longer provides a future economic benefits, such as can be used to generate revenue or have material resale value. (<Link href="https://corporatefinanceinstitute.com/resources/accounting/matching-principle/#:~:text=The matching principle is an,, quarter, or month)." target="_blank">Capitalization vs. Expense</Link>).</li>
        </ul>

        <button onClick={onCloseButtonClick} className="button-navi close-button-bottom">Close</button>
      </div>
    </div>
  );
}
