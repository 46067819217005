
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import {
  courseMembersQuery,
  gamesQuery,
  gamesSessionsQuery
} from '../api';

import { navigation } from '../data';
import SubPagesLayout from '../../../UI/layout/subPages';

import './styles.css';
import Table from '../../../UI/table';

export default function InstructorProgress() {
  const navigate = useNavigate();

  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [progressInfo, setProgressInfo] = useState([]);

  const { data: courseMembers } = useQuery(courseMembersQuery()) || {};
  const { data: games } = useQuery(gamesQuery()) || {};
  const { data: sessions } = useQuery(gamesSessionsQuery()) || {};

  useEffect(() => {
    if(courseMembers && games && sessions) {

      const studentsCourseMembers = courseMembers.filter(member => member.is_student);

      const activeGames = games.filter(game => game.is_active);

      let headers = ['Student’s Name', 'Overall', ...activeGames.map(game => game.name)];
      setTableHeaders(headers);

      const data = studentsCourseMembers
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(student => {
          const userSessions = sessions.filter(session => session.user === student.user_id);

          const hasStarted = userSessions.length > 0;
          const allCompleted = hasStarted && activeGames.every(game =>
            userSessions.some(session => session.game === game.id && session.completed)
          );

          const overallStatus = !hasStarted
            ? 'Not Started'
            : allCompleted
            ? 'Completed'
            : 'In Progress';

          const studentData = [
            student.name,
            overallStatus,
            ...activeGames.map(game => {
              const gameSessions = userSessions.filter(session => session.game === game.id);

              if (!gameSessions.length) {
                return 'Not Started';
              }

              if (gameSessions.some(session => session.completed)) {
                return 'Completed';
              }

              return 'In Progress';
            })
          ];

          return studentData;
        });

      setTableData(data);

      const startedCount = data.filter(row => row[1] === 'In Progress' || row[1] === 'Completed').length;
      const completedCount = data.filter(row => row[1] === 'Completed').length;
      const totalStudents = studentsCourseMembers.length;

      let progressInfo = <p>No student has started an assignment.</p>;

      if (completedCount === totalStudents) {
        progressInfo = <p>Every student has finished all the assignments.</p>;
      } else if (startedCount > 0) {
        progressInfo = <p>As of today, <b>{startedCount}</b> out of <b>{totalStudents}</b> students in the class have started at least one assignment, with <b>{completedCount}</b> completing all assignments.</p>
      }

      setProgressInfo(progressInfo);
    }
  }, [courseMembers, games, sessions]);

  const handlePrevButtonClick = () => {
    navigate('/instructor/insights/decision-insights/balanced/1' );
  }

  return (
    <SubPagesLayout
      navigation={navigation}
      onFirstButtonClick={handlePrevButtonClick}
      currentPage={1}
      pagesQuantity={1}
      contentClassName="decision-insights-content-container"
    >
      <h1>Insights</h1>
      <h2>Progress</h2>
      {progressInfo}
      
      <Table headers={tableHeaders} rows={tableData} />
    </SubPagesLayout>
  );
}
