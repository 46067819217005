import './styles.css';
import Hint from '../../hint';

export default function TransactionRecordsHeader({
  transactionText = '',
  ishintShown,
  hintContent,
  onHintButtonClick
}) {
  return (
    <div className='transaction-records-header'>
      <h2>Record the following transaction:</h2>
      <p inert="inert">{transactionText}</p>
      
      {
        ishintShown &&
          <Hint 
            content={hintContent}
            buttonText="Next"
            onButtonClick={onHintButtonClick}
          />
      }
    </div>
  );
}
