import { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';

import useAuth from '../../auth/useAuth';
import { useSoundStatus } from '../../context/SoundStatusContext';

import Layout from '../UI/introGameLayout/layout';

import BackgroundImage from './images/game-background.jpg';

import GameProcessSound from './sounds/game-process.mp3';

import ClickSound from './sounds/click.mp3';
import AnswerCorrectSound from './sounds/answer-correct.mp3';
import AnswerWrongSound from './sounds/answer-wrong.mp3';
import UpdateBalanceSheetSound from './sounds/update-balance-sheet.mp3';

import { 
  getGamesSessions,
  scenarioLevelsQuery,
  makeTransactionDecision,
  getTransactionDecisions,
  gamesQuery,
  createGameSession,
  updateGameSession,
  principlesQuestionsQuery,
  transactionsQuery,
  itemsQuery
} from './api';

import { 
  transactionsAnswerOptions
} from './data';

import { calculateTimeInSeconds } from '../../utils';

import Intro from '../UI/game/intro';

import TransactionsDecisions from '../UI/thePrinciples/transactionsDecisions';

import FinancialStatement from '../UI/thePrinciples/financialStatement';

import './styles.css';
import ExpenseRecognitionModal from '../UI/thePrinciples/transactionsDecisions/modal';
import Modal from '../UI/modal';
// import BalanceSheet from '../UI/thePrinciples/balanceSheet';
import BalanceSheet from '../UI/thePrinciples/financialStatement/balanceSheet';
import EmmaMessageResults from '../UI/thePrinciples/emmaMessagesResults';

export default function ThePrinciples() {
  const gameProcessEffect = useRef();

  const clickEffect = useRef();
  const answerCorrectEffect = useRef();
  const answerWrongEffect = useRef();
  const updateBalanceSheetEffect = useRef();
  
  useEffect(() => {
    const gameProcessAudio = new Audio(GameProcessSound);
    gameProcessAudio.preload = 'auto';  
    gameProcessEffect.current = gameProcessAudio;

    const clickAudio = new Audio(ClickSound);  
    clickAudio.preload = 'auto';  
    clickEffect.current = clickAudio;

    const answerCorrectAudio = new Audio(AnswerCorrectSound);  
    answerCorrectAudio.preload = 'auto';  
    answerCorrectEffect.current = answerCorrectAudio;
    
    const answerWrongAudio = new Audio(AnswerWrongSound);  
    answerWrongAudio.preload = 'auto';  
    answerWrongEffect.current = answerWrongAudio;

    const updateBalanceSheetAudio = new Audio(UpdateBalanceSheetSound);  
    updateBalanceSheetAudio.preload = 'auto';  
    updateBalanceSheetEffect.current = updateBalanceSheetAudio;
    updateBalanceSheetEffect.current.volume = 0.3;
  
    return () => {
      gameProcessAudio.pause();
      clickAudio.pause();
      answerCorrectAudio.pause();
      answerWrongAudio.pause();
      updateBalanceSheetAudio.pause();
    };
  }, []);

  const { soundStatus } = useSoundStatus();

  useEffect(() => {
    const playClickSound = () => {
      if (soundStatus === 'enabled') {
        clickEffect.current.play().catch(e => console.error('Error playing click sound:', e));
      }
    };

    document.addEventListener('click', playClickSound);

    return () => {
      document.removeEventListener('click', playClickSound);
    };
  }, [soundStatus]);

  const navigate = useNavigate();

  const params = useParams();
  const levelNumber = Number(params.levelNumber);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const transactionToShow = Number(searchParams.get('transaction'));
  const transactionDecisionToShowStatus = searchParams.get('status');
  const selectedAnswerOptionToShow = searchParams.get('answer');

  const { getUser, getCurrentThePrinciplesGameId } = useAuth();

  const user = getUser();
  
  const gameId = getCurrentThePrinciplesGameId();

  const [isBalanceSheetVisible, setIsBalanceSheetVisible] = useState(false);
  const [isIncomeStatementVisible, setIsIncomeStatementVisible] = useState(false);
  const [selectedAnswerOption, setSelectedAnswerOption] = useState(null);
  const [answerStatus, setAnswerStatus] = useState(null);
  const [answerPoints, setAnswerPoints] = useState(null);
  const [isExplanationVisible, setIsExplanationVisible] = useState(false);
  const [explanationContent, setExplanationContent] = useState(null);
  const [correctlyAnsweredTransactions, setCorrectlyAnsweredTransactions] = useState([]);
  const [isExpenseRecognitionModalVisible, setIsExpenseRecognitionModalVisible] = useState(false);
  const [isWellDoneModalVisible, setIsWellDoneModalVisible] = useState(false);
  const [isTransactionsDecisionsVisible, setIsTransactionsDecisionsVisible] = useState(true);
  const [isEmmaResultsVisible, setIsEmmaResultsVisible] = useState(false);

  const [isSubmitButtonShown, setIsSubmitButtonShown] = useState(true);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

  const [revenuesTotal, setRevenuesTotal] = useState(0);
  const [expensesTotal, setExpensesTotal] = useState(0);
  
  const [levelTransactions, setLevelTransactions] = useState([]);
  const [activeTransaction, setActiveTransaction] = useState(0);

  const [assets, setAssets] = useState(0);
  const [liabilities, setLiabilities] = useState(0);
  const [equity, setEquity] = useState(0);

  const [assetsChildren, setAssetsChildren] = useState([]);
  const [liabilitiesChildren, setLiabilitiesChildren] = useState([]);
  const [equityChildren, setEquityChildren] = useState([]);
  
  const assetsChildrenRef = useRef(assetsChildren);
  const liabilitiesChildrenRef = useRef(liabilitiesChildren);
  const equityChildrenRef = useRef(equityChildren);

  const [revenuesChildren, setRevenuesChildren] = useState([]);
  const [expensesChildren, setExpensesChildren] = useState([]);
  
  const revenuesChildrenRef = useRef(revenuesChildren);
  const expensesChildrenRef = useRef(expensesChildren);

  const [scenarioId, setScenarioId] = useState(0);
  const [scenarioLevelDescription, setScenarioLevelDescription] = useState('');
  const [currentGameSessionId, setCurrentGameSessionId] = useState(0);

  const { data: scenarioLevels } = useQuery(scenarioLevelsQuery(scenarioId)) || {};

  const { data: transactionDecisions } = useQuery({
    queryKey: [`transactionDecisionsThePrinciples_${levelNumber}`, { userId: user?.id, gameId: gameId, gameSessionId: currentGameSessionId }],
    queryFn: () => getTransactionDecisions({ userId: user?.id, gameId: gameId, gameSessionId: currentGameSessionId }),
    enabled: !!user?.id && !!gameId && !!currentGameSessionId,
    refetchOnWindowFocus: false
  });

  const { data: games } = useQuery(gamesQuery()) || {};

  const { data: gamesSessions } = useQuery({
    queryKey: ['gamesSessionsThePrinciples'],
    queryFn: getGamesSessions,
    refetchOnWindowFocus: false
  });

  const queryClient = useQueryClient();

  const createGameSessionMutation = useMutation({
    mutationFn: (data) => createGameSession(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['gamesSessionsThePrinciples']);
    },
    onError() {
      console.log('Server error');
    }
  });
  
  const updateGameSessionMutation = useMutation({
    mutationFn: (data) => updateGameSession(data),
  });

  
  const { data: principlesQuestions } = useQuery(principlesQuestionsQuery()) || {};
  const { data: transactions } = useQuery(transactionsQuery()) || {};
  const { data: items } = useQuery(itemsQuery()) || {};
  
  const resetIncomeStatements = () => {
    setIsIntroVisible(true);

    setTotalScore(0);
    setAnswerStatus(null);
    setSelectedAnswerOption(null);
    setCorrectlyAnsweredTransactions([]);
    setActiveTransaction(0);
    setLevelTransactions([]);
    setTime(0);
    setItemTimeStart(0);
    setIsSubmitButtonShown(true);

    setRevenuesTotal(0);
    setExpensesTotal(0);

    setAssets(0);
    setLiabilities(0);
    setEquity(0);

    assetsChildrenRef.current = [];
    liabilitiesChildrenRef.current = [];
    equityChildrenRef.current = [];
    setAssetsChildren([]);
    setLiabilitiesChildren([]);
    setEquityChildren([]);

    revenuesChildrenRef.current = [];
    expensesChildrenRef.current = [];
    setRevenuesChildren([]);
    setExpensesChildren([]);
  }

  useEffect(() => {
    if(games) {
      const game = games.find(game => game.game_type.includes('The Principles') && game.id === gameId);

      if(game && game.scenario) {
        setScenarioId(game.scenario);
      }
    }
  }, [games, gameId]);


  useEffect(() => {
    if(games && gamesSessions && gameId && user?.id) {
      const currentGameTypeSessions = gamesSessions.filter(session => games.find(game => game.id === session.game && game.game_type === 'The Principles'));
      if(gamesSessions.length && currentGameTypeSessions.length) {
        const currentGameSessions = currentGameTypeSessions.filter(session => session.user === user.id && session.game === gameId);
      
        if (currentGameSessions.length) {
          const currentNotCompletedGameSession = currentGameSessions.find(session => session.user === user.id && session.game === gameId && !session.completed);
          if(currentNotCompletedGameSession) {
            setCurrentGameSessionId(currentNotCompletedGameSession.id);
          } else {
            if(currentGameSessions.length < 3) {
              createGameSessionMutation.mutate({ game: gameId, user: user.id });
            } else {
              const completedGameSession = currentGameTypeSessions
                .filter(session => session.user === user.id && session.game === gameId && session.completed)
                .reduce((maxSession, session) => (session.id > (maxSession?.id || 0) ? session : maxSession), null);
    
              setCurrentGameSessionId(completedGameSession?.id);
            }
          }
        } else {
          createGameSessionMutation.mutate({ game: gameId, user: user.id });
        }
      } else {
        createGameSessionMutation.mutate({ game: gameId, user: user.id });
      }
    }
  }, [games, gamesSessions, gameId, user?.id]);

  useEffect(() => {  
    if (scenarioLevels?.length && transactionDecisions && principlesQuestions?.length && transactions?.length && items?.length) {

      const scenarioLevel = scenarioLevels.find(level => level.level === levelNumber);

      if(scenarioLevel) {
        setScenarioLevelDescription(scenarioLevel.description);
        const sortedTransactions = scenarioLevel.transactions.sort((a, b) => a.order - b.order);

        setLevelTransactions(sortedTransactions);
        // let transactionNotAnsweredCorrectly = 0;
        const isAnyQuestionAnsweredCorrectly = transactionDecisions.some(decision => decision.is_correct);
        
        if(isAnyQuestionAnsweredCorrectly || transactionToShow) {
          setIsBalanceSheetVisible(true);
          setIsIncomeStatementVisible(true);
          
          if(transactionToShow) {
            setAnswerStatus(transactionDecisionToShowStatus);
            setActiveTransaction(transactionToShow - 1);
            setSelectedAnswerOption(selectedAnswerOptionToShow);
            setIsExplanationVisible(true);
          }

        }
    
        const updatedCorrectlyAnsweredTransactions = [];
        let accumulatedRevenues = 0;
        let accumulatedExpenses = 0;

        for (const transaction of sortedTransactions) {
          const relatedCorrectDecision = transactionDecisions.find(decision => decision.question === transaction.id && decision.is_correct);
    
          if (relatedCorrectDecision) {
            // Check if the transaction is already in correctlyAnsweredTransactions
            const isTransactionAdded = updatedCorrectlyAnsweredTransactions.some(item => item.id === transaction.id);
      
            if (!isTransactionAdded) {
              updatedCorrectlyAnsweredTransactions.push({
                id: transaction.id,
                answer: relatedCorrectDecision.selected_option,
                explanation: relatedCorrectDecision.explanation
              });

              setTotalScore(prevTotalScore => prevTotalScore + relatedCorrectDecision.score_points);
              if (relatedCorrectDecision.time_elapsed) {
                const timeInSeconds = calculateTimeInSeconds(relatedCorrectDecision.time_elapsed);
                setTime(prevTime => prevTime + timeInSeconds);
                setItemTimeStart(prevTime => prevTime + timeInSeconds);
              }

              const relatedPrincipleQuestion = principlesQuestions.find(question => question.id === relatedCorrectDecision.question);
              const relatedTransaction = transactions.find(transaction => transaction.id === relatedPrincipleQuestion?.transaction);

              const relatedCreditItem = items.find(item => item.id === relatedTransaction?.credit);
              const relatedDebitItem = items.find(item => item.id === relatedTransaction?.debit);

              balanceSheetChange(relatedCreditItem, 'credit', relatedTransaction.value);
              balanceSheetChange(relatedDebitItem, 'debit', relatedTransaction.value);

              if (relatedCorrectDecision.selected_option === 'Increase Profits') {
                accumulatedRevenues += relatedCorrectDecision.value;

                const relatedItem = [relatedDebitItem, relatedCreditItem].find(item =>
                  item.parent_objects.some(obj => obj.name === 'Revenues')
                );

                if (relatedItem) {
                  incomeStatementChange('revenues', relatedItem, relatedCorrectDecision.value);
                }
              } else if (relatedCorrectDecision.selected_option === 'Decrease Profits') {
                accumulatedExpenses -= relatedCorrectDecision.value;

                const relatedItem = [relatedDebitItem, relatedCreditItem].find(item =>
                  item.parent_objects.some(obj => obj.name === 'Expenses')
                );

                if (relatedItem) {
                  incomeStatementChange('expenses', relatedItem, relatedCorrectDecision.value);
                }
              }
            }
          }
        }
        setRevenuesTotal(accumulatedRevenues);
        setExpensesTotal(accumulatedExpenses);

        const retainedEarningsItem = { name: 'Retained Earnings', parent_objects: [{ name: 'Equity' }] };
        balanceSheetChange(retainedEarningsItem, 'credit', accumulatedRevenues + accumulatedExpenses);
    
        setCorrectlyAnsweredTransactions(updatedCorrectlyAnsweredTransactions);
      
        const firstUnansweredIndex = sortedTransactions.findIndex(t => !updatedCorrectlyAnsweredTransactions.some(c => c.id === t.id));
        if (firstUnansweredIndex !== -1) {
          setActiveTransaction(firstUnansweredIndex);
        } else {
          setIsWellDoneModalVisible(true);
        }
        // if (transactionNotAnsweredCorrectly > 1) {
        //   setActiveTransaction(transactionNotAnsweredCorrectly - 1);
        // }
      }
    }
  }, [levelNumber, scenarioLevels, transactionDecisions, principlesQuestions, transactions, items]);

  const updateChildrenValues = (item, valueWithSign, categoryChildrenRef, setCategoryChildren) => {
    const existingItemIndex = categoryChildrenRef.current.findIndex(child => child.name === item.name);
  
    if (existingItemIndex !== -1) {
      const updatedChildren = [...categoryChildrenRef.current];
      
      if(item.name === 'Retained Earnings') {
        updatedChildren[existingItemIndex].value = valueWithSign;
      } else {
        updatedChildren[existingItemIndex].value += valueWithSign;
      }
      if (updatedChildren[existingItemIndex].value === 0 && updatedChildren[existingItemIndex].name !== 'Retained Earnings') {
        updatedChildren.splice(existingItemIndex, 1);
      }
      setCategoryChildren(updatedChildren);
      categoryChildrenRef.current = updatedChildren;
    } else {
      const newChildren = [...categoryChildrenRef.current, { name: item.name, value: valueWithSign }];
      const retainedEarningsIndex = categoryChildrenRef.current.findIndex(child => child.name === 'Retained Earnings');

      if (retainedEarningsIndex !== -1) {
        const retainedEarnings = newChildren.splice(retainedEarningsIndex, 1)[0];
        newChildren.push(retainedEarnings);
      }

      setCategoryChildren(newChildren);
      categoryChildrenRef.current = newChildren;
    }
  };

  const balanceSheetChange = (item, normal, value) => {
    const hasParent = (name) => item.parent_objects.some(parent => parent.name === name);
  
    if (item.name === 'Assets' || hasParent('Assets')) {
      const valueWithSign = normal === 'credit' ? -value : value;
      setAssets(prevAssets => prevAssets + valueWithSign);

      if(levelNumber > 1) {
        updateChildrenValues(item, valueWithSign, assetsChildrenRef, setAssetsChildren);
      }
    } else if (item.name === 'Liabilities' || hasParent('Liabilities')) {
      const valueWithSign = normal === 'debit' ? -value : value;
      setLiabilities(prevLiabilities => prevLiabilities + valueWithSign);

      if(levelNumber > 1) {
        updateChildrenValues(item, valueWithSign, liabilitiesChildrenRef, setLiabilitiesChildren);
      }
    } else if (item.name === 'Equity' || hasParent('Equity')) {
      const valueWithSign = normal === 'debit' ? -value : value;
      
      setEquity(prevEquity => prevEquity + valueWithSign - (revenuesTotal + expensesTotal));

      if(levelNumber > 1) {
        updateChildrenValues(item, valueWithSign, equityChildrenRef, setEquityChildren);
      }
    }
  };

  const incomeStatementChange = (type, item, value) => {
    if(levelNumber > 1) {
      if(type === 'revenues') {
        updateChildrenValues(item, value, revenuesChildrenRef, setRevenuesChildren);
      } else if(type === 'expenses') {
        updateChildrenValues(item, -value, expensesChildrenRef, setExpensesChildren);
      }
    }
  }
  
  const handleAnswerOptionButtonClick = (option) => {
    setSelectedAnswerOption(option);
    setAnswerStatus(null);
    setIsExplanationVisible(false);
  };

  const handeSubmitButtonClick = () => {
    setIsSubmitButtonLoading(true);
    const questionId = levelTransactions[activeTransaction]?.id;
    const timeElapsed = time - itemTimeStart;
    
    decisionMutation.mutate({
      game: gameId,
      gameSessionId: currentGameSessionId,
      question: questionId,
      selectedOption: selectedAnswerOption,
      timeElapsed,
      gameTimeElapsed: time
    }, {
      onSuccess: (result) => {
        setIsSubmitButtonLoading(false);
        setIsExplanationVisible(true);
        if(result.is_correct) {
          answerCorrectEffect.current.play()
            .then(() => {
              answerCorrectEffect.current.onended = () => {
                const relatedPrincipleQuestion = principlesQuestions.find(question => question.id === questionId);
                const relatedTransaction = transactions.find(transaction => transaction.id === relatedPrincipleQuestion?.transaction);

                const relatedCreditItem = items.find(item => item.id === relatedTransaction?.credit);
                const relatedDebitItem = items.find(item => item.id === relatedTransaction?.debit);
  
                if (soundStatus === 'enabled') {
                  updateBalanceSheetEffect.current.play().catch(e => console.error('Error playing balance sheet update sound:', e));
                }
  
                let updatedRevenuesTotal = revenuesTotal;
                let updatedExpensesTotal = expensesTotal;

                if (selectedAnswerOption === 'Increase Profits') {
                  setRevenuesTotal(prevRevenuesTotal => {
                    updatedRevenuesTotal = prevRevenuesTotal + result.value;
                    return updatedRevenuesTotal;
                  });
                
                  const relatedItem = [relatedDebitItem, relatedCreditItem].find(item => 
                    item.parent_objects.some(obj => obj.name === 'Revenues')
                  );
                
                  if (relatedItem) {
                    incomeStatementChange('revenues', relatedItem, result.value);
                  }
                } else if (selectedAnswerOption === 'Decrease Profits') {
                  setExpensesTotal(prevExpensesTotal => {
                    updatedExpensesTotal = prevExpensesTotal - result.value
                    return updatedExpensesTotal;
                  });
                
                  const relatedItem = [relatedDebitItem, relatedCreditItem].find(item => 
                    item.parent_objects.some(obj => obj.name === 'Expenses')
                  );
                
                  if (relatedItem) {
                    incomeStatementChange('expenses', relatedItem, result.value);
                  }
                }

                if(!isBalanceSheetVisible && !isIncomeStatementVisible) {
                  setIsIncomeStatementVisible(true);
                  setTimeout(() => {
                    setIsBalanceSheetVisible(true);

                    setTimeout(() => {
                      balanceSheetChange(relatedCreditItem, 'credit', relatedTransaction.value);
                      balanceSheetChange(relatedDebitItem, 'debit', relatedTransaction.value);

                      if (selectedAnswerOption === 'Increase Profits' || selectedAnswerOption === 'Decrease Profits') {
                        const retainedEarningsItem = {name: 'Retained Earnings', parent_objects: [{name: 'Equity'}]};
                        balanceSheetChange(retainedEarningsItem, 'credit', updatedRevenuesTotal + updatedExpensesTotal);
                      }
                    }, 1000);
                  }, 2000);
                } else {
                  setTimeout(() => {
                    balanceSheetChange(relatedCreditItem, 'credit', relatedTransaction.value);
                    balanceSheetChange(relatedDebitItem, 'debit', relatedTransaction.value);

                    if (selectedAnswerOption === 'Increase Profits' || selectedAnswerOption === 'Decrease Profits') {
                      const retainedEarningsItem = {name: 'Retained Earnings', parent_objects: [{name: 'Equity'}]};
                      
                      balanceSheetChange(retainedEarningsItem, 'credit', updatedRevenuesTotal + updatedExpensesTotal);
                    }
                  }, 1500);
                  
                }
              };
            })
            .catch(e => console.log('Error playing answer correct sound:', e));

          if(selectedAnswerOption === 'Increase Profits') {
            setExplanationContent(<p>Under the <Link to="https://www.investopedia.com/terms/r/revenuerecognition.asp" title="Revenue Recognition" target="_blank">Revenue Recognition</Link> principles, revenue should be recognized when earned (i.e. when goods or services are provided to the customer). Click <b>Next</b> to continue.</p>);
          } else if(selectedAnswerOption === 'Decrease Profits') {
            setExplanationContent(<p>Under the <button onClick={handleExpenseRecognitionClick}>Expense Recognition</button> principle, expense should be recognized when incurred (i.e. when goods or services are used or consumed by the company). Click <b>Next</b> to continue.</p>);
          } else if(selectedAnswerOption === 'No Impact') {
            setExplanationContent(<p>Under the <Link to="https://www.investopedia.com/terms/r/revenuerecognition.asp" title="Revenue" target="_blank">Revenue</Link> and <button onClick={handleExpenseRecognitionClick}>Expense Recognition</button> principles, revenue or expenses should not be recognized. Click <b>Next</b> to continue.</p>);
          }

          setAnswerStatus('correct');
          setAnswerPoints(result.score_points);
          setCorrectlyAnsweredTransactions([...correctlyAnsweredTransactions, {id: questionId, answer: selectedAnswerOption}]);
          setTotalScore(prevTotalScore => prevTotalScore + result.score_points);
          setShouldResetItemTimeStart(true);      
        } else {
          answerWrongEffect.current.play().catch(e => console.log('Error playing answer wrong sound:', e));
          setAnswerStatus('incorrect');
          
          if(selectedAnswerOption === 'Increase Profits') {
            setExplanationContent(<p>Under the <Link to="https://www.investopedia.com/terms/r/revenuerecognition.asp" title="Revenue Recognition" target="_blank">Revenue Recognition</Link> principles, revenue should not be recognized because goods or services were not provided to the customer. Click <b>Next</b> to continue.</p>);
          } else if(selectedAnswerOption === 'Decrease Profits') {
            setExplanationContent(<p>Under the <button onClick={handleExpenseRecognitionClick}>Expense Recognition</button> principles, expense should not be recognized since goods or services were not used or consumed by the company. Click <b>Next</b> to continue.</p>);
          } else if(selectedAnswerOption === 'No Impact') {
            setExplanationContent(<p>Review the <Link to="https://www.investopedia.com/terms/r/revenuerecognition.asp" title="Revenue" target="_blank">Revenue</Link> and <button onClick={handleExpenseRecognitionClick}>Expense Recognition</button> principles. Click <b>Next</b> to continue.</p>);
          }
        }
      },
      onError: (error) => {
        console.log('Error: ', error);
      }
    });

    setIsSubmitButtonShown(false);
  }

  const handleNextButtonClick = () => {
    if(correctlyAnsweredTransactions.length === levelTransactions.length) {
      setIsPlaying(false);
      setIsWellDoneModalVisible(true);

      updateGameSessionMutation.mutate({
        id: currentGameSessionId,
        levelNumber,
        status: 'completed',
        isSessionCompleted: scenarioLevels.length === levelNumber,
      });
    } else { 
      setIsSubmitButtonShown(true);

      if(!correctlyAnsweredTransactions.some(correctlyAnsweredTransaction => correctlyAnsweredTransaction.id === levelTransactions[activeTransaction + 1]?.id)) {
        if(shouldResetItemTimeStart) {
          setItemTimeStart(time);
          setShouldResetItemTimeStart(false);
        }
      }
      
      let nextTransactionIndex = activeTransaction + 1;
      while (nextTransactionIndex < levelTransactions.length && correctlyAnsweredTransactions.some(t => t.id === levelTransactions[nextTransactionIndex].id)) {
        nextTransactionIndex++;
      }

      // If we reached the end, loop back to find the first unanswered transaction
      if (nextTransactionIndex >= levelTransactions.length) {
        nextTransactionIndex = 0;
        while (nextTransactionIndex < levelTransactions.length && correctlyAnsweredTransactions.some(t => t.id === levelTransactions[nextTransactionIndex].id)) {
          nextTransactionIndex++;
        }
      }

      setActiveTransaction(nextTransactionIndex);

      setIsExplanationVisible(false);
      setSelectedAnswerOption(null);
      setAnswerStatus(null);
    }
  };

  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {  
    if (isPlaying && soundStatus === 'enabled') {
      gameProcessEffect.current.volume = 0.25;
      gameProcessEffect.current.loop = true;
      gameProcessEffect.current.currentTime = 0;
      gameProcessEffect.current.play().catch(e => console.log('Error playing game process sound:', e));
    } else {
      gameProcessEffect.current.pause();
    }
  
    return () => {
      gameProcessEffect.current.pause();
    };
  }, [isPlaying, soundStatus]);

  const [time, setTime] = useState(0);
  const [itemTimeStart, setItemTimeStart] = useState(0);
  const [shouldResetItemTimeStart, setShouldResetItemTimeStart] = useState(false);
  const timeRef = useRef(time);
  useEffect(() => {
    timeRef.current = time;
  }, [time]);

  useEffect(() => {
    if (!isPlaying) return;
    const timerInterval = setInterval(() => {
      const newTime = timeRef.current + 1;
      setTime(newTime);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [isPlaying]);

  const [totalScore, setTotalScore] = useState(0);
  const decisionMutation = useMutation({
    mutationFn: (data) => makeTransactionDecision(data)
  });

  const [isIntroVisible, setIsIntroVisible] = useState(true);

  const handleIntroClose = () => {
    setIsIntroVisible(false);
  }

  useEffect(() => {
    if(!isIntroVisible && !isWellDoneModalVisible 
      && (levelTransactions?.length && correctlyAnsweredTransactions?.length !== levelTransactions?.length)) {
      setIsPlaying(true);
    }
  }, [isIntroVisible, isWellDoneModalVisible, levelTransactions, correctlyAnsweredTransactions]);
  
  const handleExpenseRecognitionClick = () => {
    setIsExpenseRecognitionModalVisible(true);
  };
  
  const handleExpenseRecognitionModalCloseButtonClick = () => {
    setIsExpenseRecognitionModalVisible(false);
  }

  const handleNextLevelButtonClick = () => {
    if(scenarioLevels?.length === levelNumber) {
      setIsTransactionsDecisionsVisible(false);
      setIsEmmaResultsVisible(true);
    } else {
      navigate(`/the-principles/level/${levelNumber + 1}`);
      resetIncomeStatements();
    }
    setIsExplanationVisible(false);
    setIsWellDoneModalVisible(false);
  }

  const handleEmmaOnViewResultsClick = () => {
    navigate('/the-principles/summary');
  }

  return(
    <Layout
      backgroundImage={BackgroundImage}
      isBackgroundBlurred={true}
      isHeaderVisible={true}
      isFooterVisible={true}
      time={time}
      totalScore={totalScore}
      isAvatarVisible={true}
      isExplanationVisible={isExplanationVisible}
      explanationName={answerStatus === 'incorrect' ? 'Bailey' : 'Benny'}
      answerStatus={answerStatus}
      explanationContent={explanationContent}
      shouldExplanationContentBeParsed={false}
      scoreLabel="Points"
    >
      {
        isIntroVisible && (
          <Intro
            title={`Level ${levelNumber}`}
            description={scenarioLevelDescription}
            onShowingCompleted={handleIntroClose}
          />
      )}
      
      <BalanceSheet
        title="Balance Sheet"
        assets={assets}
        liabilities={liabilities}
        equity={equity}
        assetsChildren={assetsChildren}
        liabilitiesChildren={liabilitiesChildren}
        equityChildren={equityChildren}
        shouldSlideIn={!isBalanceSheetVisible}
      />

      {
        isTransactionsDecisionsVisible && 
          <TransactionsDecisions
            key={activeTransaction}
            transactions={levelTransactions}
            activeTransaction={activeTransaction}
            answerOptions={transactionsAnswerOptions}
            selectedAnswerOption={selectedAnswerOption}
            answerStatus={answerStatus}
            answerPoints={answerPoints}
            transactionToShow={transactionToShow}
            correctlyAnsweredTransactions={correctlyAnsweredTransactions}
            onAnswerOptionClick={handleAnswerOptionButtonClick}
            isSubmitButtonShown={isSubmitButtonShown}
            isSubmitButtonLoading={isSubmitButtonLoading}
            onSubmitButtonClick={handeSubmitButtonClick}
            onNextButtonClick={handleNextButtonClick}
            shouldFirstQuestionBeShown={correctlyAnsweredTransactions?.length < 1 && levelNumber === 1}
          />
      }
      
      {
        isEmmaResultsVisible && 
          <EmmaMessageResults onViewResultsClick={handleEmmaOnViewResultsClick} />
      }

      <FinancialStatement 
        title="Income Statement"
        revenues={revenuesTotal}
        expenses={expensesTotal}
        revenuesChildren={revenuesChildren}
        expensesChildren={expensesChildren}
        shouldSlideIn={!isIncomeStatementVisible}
      />

      {isExpenseRecognitionModalVisible && (
        <ExpenseRecognitionModal
          isModalOverlayVisible={true}
          onCloseButtonClick={handleExpenseRecognitionModalCloseButtonClick}
        />
      )}

      {isWellDoneModalVisible && (
        <Modal
          content={
            <>
              <div className="modal-center vertical">
                <div><span>Well done!</span></div>
                {
                  <button onClick={handleNextLevelButtonClick} className="button-blue button-view-summary">Next</button>
                }
              </div>
            </>
          }
          isModalSmall={true}
        />
      )}
    </Layout>
  );
}
