import axiosClient from '../../api/axiosClient.js';
import { fetchPageData } from '../../api/utils.js';

export async function getGames() {
  return fetchPageData(`/api/games/games/`);
}

export const gamesQuery = () => {
  return {
    queryKey: ['games'],
    queryFn: async () => getGames(),
    refetchOnWindowFocus: false,
    cacheTime: 0
  };
};

async function getScenarioLevels(scenarioId) {  
  return scenarioId ? fetchPageData(`/api/games/scenario-levels/?scenarios=${scenarioId}`) : [];
}

export const scenarioLevelsQuery = (scenarioId) => {
  return {
    queryKey: ['scenarioLevels', scenarioId],
    queryFn: async () => getScenarioLevels(scenarioId),
    refetchOnWindowFocus: false
  };
};

export async function makeTransactionDecision({
  game,
  gameSessionId,
  question,
  selectedOption,
  timeElapsed,
  gameTimeElapsed
}) {
  const { data } = await axiosClient.post(`api/principles/decisions/`, {
    game,
    game_session: gameSessionId,
    question,
    selected_option: selectedOption,
    time_elapsed: timeElapsed,
    game_time_elapsed: gameTimeElapsed
  });

  return data;
}

export async function getGamesSessions() {
  return fetchPageData(`/api/games/sessions/`);
}

export async function createGameSession({game, user}) {
  const { data } = await axiosClient.post(`/api/games/sessions/`, {
    game,
    user,
    is_ready: true
  });

  return data;
}

export async function updateGameSession({id, levelNumber, status, isSessionCompleted}) {
  const levelKey = `level${levelNumber}`;
  const requestBody = {
    is_ready: true,
    [levelKey]: status,
  };

  if (isSessionCompleted) {
    requestBody.completed = true;
  }

  const { data } = await axiosClient.patch(`/api/games/sessions/${id}/`, requestBody);

  return data;
}

export async function getScoresStats({gameId}) {
  const response = await axiosClient.get(`/api/principles/scores/stats/${gameId}/`);
  return response.data;
}

export const scoresStatsQuery = ({gameId}) => {
  return {
    queryKey: ['scoresStats'],
    queryFn: async () => getScoresStats({gameId}),
    refetchOnWindowFocus: false
  };
};

export async function getScoresPrinciples({userId, gameId}) {
  const response = await axiosClient.get(`/api/principles/scores/principles/?user=${userId}&game=${gameId}`);
  return response.data;
}

export const scoresPrinciplesQuery = ({userId, gameId}) => {
  return {
    queryKey: ['scoresPrinciples'],
    queryFn: async () => getScoresPrinciples({userId, gameId}),
    refetchOnWindowFocus: false
  };
};


export async function getTransactionDecisions({userId, gameId, gameSessionId}) {
  return fetchPageData(`/api/principles/decisions/?user=${userId}&game=${gameId}&game_session=${gameSessionId}`);
}

export const decisionsQuery = ({userId, gameId}) => {
  return {
    queryKey: ['decisions', { userId, gameId }],
    queryFn: () => getTransactionDecisions({ userId, gameId }),
    refetchOnWindowFocus: false
  };
};


export async function getPrinciplesQuestions() {
  return fetchPageData(`/api/principles/principles-questions/`);
}

export const principlesQuestionsQuery = () => {
  return {
    queryKey: ['principlesQuesions'],
    queryFn: async () => getPrinciplesQuestions(),
    refetchOnWindowFocus: false,
    cacheTime: 0
  };
};

export async function getTransactions() {
  return fetchPageData(`/api/games/transactions/`);
}

export const transactionsQuery = () => {
  return {
    queryKey: ['transactionsThePrinciples'],
    queryFn: async () => getTransactions(),
    refetchOnWindowFocus: false,
    cacheTime: 0
  };
};


async function getItems() {
  return fetchPageData(`/api/games/items/`);
}

export const itemsQuery = () => {
  return {
    queryKey: ['itemsThePrinciples'],
    queryFn: async () => getItems(),
    refetchOnWindowFocus: false,
    cacheTime: 0
  };
};