import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import useAuth from '../../auth/useAuth';

import Layout from '../UI/introGameLayout/layout';

import BackgroundImage from './images/debrief-background.jpg';

import { 
  getGamesSessions,
  scoresPrinciplesQuery
} from './api';

import { popupMessagesSummary } from './data';

import PopupMessages from '../UI/introGameLayout/popupMessages';

import './styles.css';
import { LeaderboardOverall } from '../UI/instructor/leaderboard/overall';

export default function SummaryThePrinciples() {
  const { getUser, getCurrentThePrinciplesGameId } = useAuth();

  const user = getUser();
  
  const currentGameId = getCurrentThePrinciplesGameId();

  const [data, setData] = useState([]);

  const [currentGameSessionId, setCurrentGameSessionId] = useState(0);

  const { data: gamesSessions } = useQuery({
    queryKey: ['gamesSessionsSummary'],
    queryFn: getGamesSessions,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if(gamesSessions && currentGameId && user) {
      const currentGameSession = gamesSessions.find(session => session.user === user?.id && session.game === currentGameId && !session.completed);
      
      if (currentGameSession) {
        setCurrentGameSessionId(currentGameSession?.id);
      } else {
        const completedGameSession = gamesSessions
          .filter(session => session.user === user?.id && session.game === currentGameId && session.completed)
          .reduce((maxSession, session) => (session.id > (maxSession?.id || 0) ? session : maxSession), null);
  
        setCurrentGameSessionId(completedGameSession?.id);
      }
    }
  }, [gamesSessions, currentGameId, user]);

  const { data: scoresPrinciples } = useQuery(scoresPrinciplesQuery({ userId: user?.id, gameId: currentGameId }));

  useEffect(() => {
    if(scoresPrinciples && currentGameSessionId) {
      formatLeaderboardData();
    }
  }, [scoresPrinciples, currentGameSessionId]);

  const calculateAverage = (data, param) => {
    if (!data || data.length === 0) return 0;

    let sum = 0;
    let count = 0;

    data.forEach(record => {
      const value = record[param];
      if (value !== null && value !== undefined) {
        sum += value;
        count++;
      }
    });

    return count > 0 ? sum / count : 0;
  };

  const formatLeaderboardData = () => {
    let leaderboardData = [];

    const currentGameSessionScoresPrinciples = scoresPrinciples.filter(scoresPrinciple => scoresPrinciple.game_session === currentGameSessionId);

    const revenueRecognitionScore = calculateAverage(currentGameSessionScoresPrinciples.filter(scoresPrinciple => scoresPrinciple.principle_name === 'Revenue Recognition'), 'total_accuracy');
    const expenseRecognitionScore = calculateAverage(currentGameSessionScoresPrinciples.filter(scoresPrinciple => scoresPrinciple.principle_name === 'Matching' || scoresPrinciple.principle_name === 'Capitalized vs Expense'), 'total_accuracy');
    const otherScore = calculateAverage(currentGameSessionScoresPrinciples.filter(scoresPrinciple => scoresPrinciple.principle_name === 'Investment by Owners'), 'total_accuracy');

    leaderboardData = [
      {
        name: 'Revenue Recognition',
        score: revenueRecognitionScore,
        formattedScore: (revenueRecognitionScore * 100).toFixed(2) + '%',
        backgroundColor: '#70A1FF'
      },
      {
        name: 'Expense Recognition',
        score: expenseRecognitionScore,
        formattedScore: (expenseRecognitionScore * 100).toFixed(2) + '%',
        backgroundColor: '#70A1FF'
      },
      {
        name: 'Other',
        score: otherScore,
        formattedScore: (otherScore * 100).toFixed(2) + '%',
        backgroundColor: '#70A1FF'
      }
    ];

    setData(leaderboardData);
  }

  return(
    <Layout
      backgroundImage={BackgroundImage}
      isBackgroundBlurred={false}
      isHeaderVisible={true}
      isFooterVisible={false}
    >
      <PopupMessages
        messages={popupMessagesSummary}
        messageSize={'extra-small'}
      />

      <LeaderboardOverall
        size={'small'}
        title={'Accuracy'}
        data={data}
        isBigScores={true}
        bottomContent={<ul><li>Finance activities involving raising or repaying capital.</li></ul>}
      />
    </Layout>
  );
}
