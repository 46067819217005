import { useNavigate } from 'react-router-dom';

import Layout from '../UI/introGameLayout/layout';
import PopupMessages from '../UI/introGameLayout/popupMessages';
import './styles.css';

import BackgroundImage from './images/game-background.jpg';

import { popupMessagesGameStart } from './data';

export default function ThePrinciplesIntroMessages() {
  const navigate = useNavigate();

  const handlePopupMessagesButtonClick = () => {
    navigate('/the-principles/level/1');
  };

  return(
    <Layout
      backgroundImage={BackgroundImage}
      isBackgroundBlurred={true}
      isHeaderVisible={false}
      isFooterVisible={false}
    >
      <PopupMessages
        messages={popupMessagesGameStart}
        buttonText="Let's go"
        onButtonClick={handlePopupMessagesButtonClick}
        messageSize="small"
      />
    </Layout>
  );
}
