import { useNavigate } from 'react-router-dom';

import Layout from '../UI/introGameLayout/layout';

import IntroVideo from './videos/intro.mp4';

import './styles.css';
import Video from '../UI/introGameLayout/video';

export default function ClassificationIntro() {
  const navigate = useNavigate();

  const handleContinueButtonClick = () => {
    navigate('/classification/level/1/tutorial');
  }

  return(
    <Layout
      isHeaderVisible={true}
      isContentVertical={true}
      backgroundColor={'#3A507B'}
    >
      <div className="intro-video-wrapper">
        <Video videoSrc={IntroVideo} />
        <button className="intro-button" onClick={handleContinueButtonClick}>Continue</button>
      </div>
    </Layout>
  );
}
