import { useNavigate } from 'react-router-dom';

import SubPagesLayout from '../../UI/layout/subPages';

import { navigation } from './data';

export default function InstructorWelcomeSettingUpForClass() {
  const navigate = useNavigate();

  const handlePrevButtonCLick = () => {
    navigate('/instructor/welcome/ways-to-use');
  }

  return (
    <SubPagesLayout
      navigation={navigation}
      onFirstButtonClick={handlePrevButtonCLick}
    >
      <h2>Setting up class</h2>
      <p>To set up the games for your class, please email us the below (info@marketgames.io):</p>
      <ol className="decimal">
        <li>Provide the student list (names,emails) or just the student count (we can create generic accounts).</li>
        <li>Please confirm the list of games you would like your students to access. These are the options:
          <ol className="lower-alpha">
            <li>Classification</li>
            <li>Normal Balance</li>
            <li>The Principles</li>
            <li>Balanced Equation</li>
          </ol>
        </li>
        <li>Provide the name, author, and/or edition of the textbook that you use for your class?</li>
      </ol>
    </SubPagesLayout>
  );
}
