export const defaultGameDuration = 180; // secons
export const defaultFallingSpeed = 1000; // ms


export const pixelsPerFall = 25; // px

export const blockHeight = 32; // px
export const blockMaxWidth = 92; // px
export const fallingAreaBottomGap = 19; // px


export const gameTypeMappings = {
  'Account Classification': 'classification',
  'Normal Balance': 'normal',
  'Balanced Equation': 'equation',
  'The Principles': 'principles',
};