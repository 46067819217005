import './styles.css';

import OptionItemsRow from './optionItemsRow';
import Hint from '../../hint';

export default function TransactionRecordsFooter({
  changeTypeOptions,
  coreTypeOptions,
  transactionTypeOptions,
  isSubmitButtonShown,
  onSubmitButtonClick,
  onSubmitButtonMouseEnter,
  onSubmitButtonMouseLeave,
  onNextButtonClick,
  isSubmitButtonLoading,
  isButtonDisabled,
  isTopHintShown,
  isChangeHintShown,
  changeHintContent,
  isCoreHintShown,
  isTransactionHintShown,
  onChangeHintButtonClick,
  onCoreHintButtonClick,
  onTransactionHintButtonClick,
  isSubmitHintShown,
  submitHintContent,
  onSubmitHintButtonClick,
  isItemsDisabled
}) {

  const coreHintContent = <span><b>Decision 2:</b> decide which accounts are impacted.</span>;
  const transactionHintContent = <span><b>Decision 3:</b> decide if the selected account should be debited or credited.</span>;

  const isAnyHintShown = isTopHintShown || isChangeHintShown || isCoreHintShown || isTransactionHintShown;
  
  return (
    <div className="transaction-records-footer">
      <span className="drag-drop-label">Drag & Drop</span>
      <div className="item-rows-wrapper">
        <OptionItemsRow 
          options={changeTypeOptions}
          isHintShown={isChangeHintShown} 
          hintContent={changeHintContent}
          onHintButtonClick={onChangeHintButtonClick}
          isItemsDisabled={isAnyHintShown || isItemsDisabled}
        />
        <OptionItemsRow
          options={coreTypeOptions}
          isHintShown={isCoreHintShown}
          hintContent={coreHintContent}
          onHintButtonClick={onCoreHintButtonClick}
          isItemsDisabled={isAnyHintShown || isItemsDisabled}
        />
        <OptionItemsRow 
          options={transactionTypeOptions}
          isHintShown={isTransactionHintShown}
          hintContent={transactionHintContent}
          onHintButtonClick={onTransactionHintButtonClick}
          isItemsDisabled={isAnyHintShown || isItemsDisabled}
        />
      </div>

      <div className="submit-transaction-records-wrapper">
        {
          isSubmitButtonShown &&
            <>
              <button
                className={`button-navi submit-transaction-records ${isSubmitButtonLoading ? 'loading' : ''} ${isButtonDisabled ? 'disabled' : ''}`}
                onClick={() => {
                  if (!isButtonDisabled) {
                    onSubmitButtonClick();
                  }
                }}
                onMouseEnter={onSubmitButtonMouseEnter}
                onMouseLeave={onSubmitButtonMouseLeave}
              >
                {!isSubmitButtonLoading && 'Submit'}
              </button>

              {/* <div className="submit-hint-wrapper"> */}
                {
                  isSubmitHintShown &&
                    <Hint 
                      content={submitHintContent}
                      buttonText="Next"
                      onButtonClick={onSubmitHintButtonClick}
                    />
                }
              {/* </div> */}
          </>
        }
        {
          !isSubmitButtonShown &&
            <button className="button-navi submit-transaction-records" onClick={onNextButtonClick}>Next</button>
        }
      </div>
    </div>
  );
}
