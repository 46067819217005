
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { 
  gamesQuery,
  getCourseMembers,
  getClassificationScores,
  getNormalScores,
  getPrinciplesScores,
  getPrinciplesScoresByQuestion,
  getBalancedEquationScores,
  getClassificationScoresByQuestion,
  getNormalScoresByQuestion  
} from '../api';

import './styles.css';

import { sortAndSelectTop } from '../../../../utils';
import SubPagesLayout from '../../../UI/layout/subPages';

import { navigation } from '../data';
import { LeaderboardOverall } from '../../../UI/instructor/leaderboard/overall';

export default function InstructorInsightsAccuracy() {
  const navigate = useNavigate();

  const [courseId, setCourseId] = useState(0);

  const [scoreBy, setScoreBy] = useState('account');
  const [sort, setSort] = useState('asc');
  const [isRandomOrder, setIsRandomOrder] = useState(true);
  const [isByAccountDisabled, setIsByAccountDisabled] = useState(false);

  const [filterBy, setFilterBy] = useState('Classification');
  const [data, setData] = useState(null);

  const gamesResponse = useQuery(gamesQuery()) || {};
  const games = gamesResponse.data;

  useEffect(() => {
    if (games) {
      let game = games.find(game => game.game_type.includes('Account Classification'));
  
      if (!game) {
        game = games[0];
      }
  
      if (game) {
        setCourseId(game.course);
      }
    }
  }, [games]);

  const { data: classificationScores } = useQuery({
    queryKey: ['classificationScoresAccuracy'],
    queryFn: async () => getClassificationScores(),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { data: normalScores } = useQuery({
    queryKey: ['normalScoresAccuracy'],
    queryFn: async () => getNormalScores(),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { data: balancedEquationScores } = useQuery({
    queryKey: ['balancedEquationScoresAccuracy'],
    queryFn: async () => getBalancedEquationScores(),
    retry: false,
    refetchOnWindowFocus: false
  });

  const { data: principlesScores } = useQuery({
    queryKey: ['principlesScoresAccuracy'],
    queryFn: async () => getPrinciplesScores(),
    refetchOnWindowFocus: false,
    retry: false,
  });
    
  const { data: classificationScoresByQuestion } = useQuery({
    queryKey: ['classificationScoresByQuestionAccuracy'],
    queryFn: async () => getClassificationScoresByQuestion(),
    refetchOnWindowFocus: false,
    retry: false,
  });
    
  const { data: normalScoresByQuestion } = useQuery({
    queryKey: ['normalScoresByQuestionAccuracy'],
    queryFn: async () => getNormalScoresByQuestion(),
    refetchOnWindowFocus: false,
    retry: false,
  });
    
  const { data: principlesScoresByQuestion } = useQuery({
    queryKey: ['principlesScoresByQuestionAccuracy'],
    queryFn: async () => getPrinciplesScoresByQuestion(),
    refetchOnWindowFocus: false,
    retry: false,
  });
  
  const { data: courseMembers } = useQuery({
    queryKey: ['courseMembers'],
    queryFn: async () => getCourseMembers(courseId),
    enabled: !!courseId,
    retry: false,
  });
  
  const filterByOptions = [...new Set(games?.map(game => {
    switch (game.game_type) {
      case 'Account Classification':
        return 'Classification';
      case 'Normal Balance':
        return 'Normal';
      case 'Balanced Equation':
        return 'Double Entry';
      default:
        return game.game_type;
    }
  }))].sort((a, b) => {
    const order = ['Classification', 'Normal', 'The Principles', 'Double Entry'];
    return order.indexOf(a) - order.indexOf(b);
  });
  
  const handleScoreByClick = (selectedScoreBy) => {
    setScoreBy(selectedScoreBy);
    setSort('desc');
    setIsRandomOrder(true);
  };

  const handleSortClick = () => {
    setIsRandomOrder(false);
    setSort(prevSort => (prevSort === 'desc' ? 'asc' : 'desc'));
  };

  const formatLeaderboardData = () => {
    let wholeLeaderboardData = [];
    let scores, scoresByQuestion;

    if(filterBy === 'Classification') {
      scores = classificationScores;
      scoresByQuestion = classificationScoresByQuestion;
    } else if(filterBy === 'Normal') {
      scores = normalScores;
      scoresByQuestion = normalScoresByQuestion;
    } else if(filterBy === 'Double Entry') {
      scores = balancedEquationScores;
    } else if(filterBy === 'The Principles') {
      scores = principlesScores;
      scoresByQuestion = principlesScoresByQuestion;
    }
    
    if(scoreBy === 'player') {
      wholeLeaderboardData = courseMembers.map(courseMember => {
        const userScores = scores.filter(score => score.user === courseMember.user_id);
        const totalAccuracy = userScores.reduce((sum, score) => sum + score.total_accuracy, 0);
        const averageAccuracy = userScores.length > 0 ? totalAccuracy / userScores.length : 0;
        const formattedScore = (averageAccuracy * 100).toFixed(2) + '%';
        
        return {
          name: courseMember.name,
          score: averageAccuracy,
          formattedScore
        }
      });
    } else if(scoreBy === 'account') {
      wholeLeaderboardData = Object.values(scoresByQuestion.reduce((acc, curr) => {
        const { question_name, principle_name, total_accuracy } = curr;
        const name = filterBy ==='The Principles' 
          ? principle_name
          : question_name;
      
        if (acc[name]) {
          acc[name].total += total_accuracy;
          acc[name].count += 1;
        } else {
          acc[name] = {
            name: name,
            total: total_accuracy,
            count: 1,
          };
        }
      
        let score;
        score = acc[name].total / acc[name].count;
        acc[name].formattedScore = (score * 100).toFixed(2) + '%';
        acc[name].score = score;
      
        return acc;
      }, {}));
    }

    const sortedLeaderboardData = sortAndSelectTop(wholeLeaderboardData, sort, isRandomOrder)
      .map((item, index) => ({
        ...item,
        backgroundColor: index < 3 ? '#5C91F5' : index < 6 ? '#A98DF6' : undefined
      }));

    setData(sortedLeaderboardData);
  }

  useEffect(() => {
    if(classificationScores && normalScores && balancedEquationScores && classificationScoresByQuestion && normalScoresByQuestion && principlesScoresByQuestion && courseMembers) {
      if(filterByOptions[0] !== 'Classification') {
        setFilterBy(filterByOptions[0]);

        if(filterByOptions[0] === 'Double Entry') {
          setScoreBy('player');
          setIsByAccountDisabled(true);
        }
      }

      formatLeaderboardData();
    }
  }, [classificationScores, normalScores, balancedEquationScores, classificationScoresByQuestion, normalScoresByQuestion, principlesScoresByQuestion, courseMembers]);

  useEffect(() => {
    if(classificationScores && normalScores && balancedEquationScores && classificationScoresByQuestion && normalScoresByQuestion && principlesScoresByQuestion && courseMembers) {
      formatLeaderboardData();
    }
  }, [filterBy, sort, scoreBy]);

  const handleFilterClick = (selectedFilterBy) => {
    setIsRandomOrder(true);
    setSort('asc');
    setFilterBy(selectedFilterBy);

    if(selectedFilterBy === 'Double Entry') {
      setScoreBy('player');
      setIsByAccountDisabled(true);
    } else {
      setIsByAccountDisabled(false);
    }
  }
  
  const maxScore = data ? data.reduce((max, item) => (item.score > max ? item.score : max), -Infinity) : 0;

  const handlePrevButtonClick = () => {
    navigate('/instructor/insights/leaderboard/points');
  }

  const handleNextButtonClick = () => {
    navigate('/instructor/insights/leaderboard/average-time');
  }

  return (
    <SubPagesLayout
      navigation={navigation}
      contentClassName="insights-content-container"
      onFirstButtonClick={handlePrevButtonClick}
      onSecondButtonClick={handleNextButtonClick}
    >
      <h1>Insights | Leaderboard</h1>
      <h2>Accuracy</h2>
      <p>This chart displays the class's accuracy in categorizing the various accounts correctly.</p>
      <LeaderboardOverall
        title="Accuracy"
        data={data}
        filterBy={filterBy}
        filterByOptions={filterByOptions}
        onFilterByClick={handleFilterClick}
        maxScore={maxScore}
        sort={sort}
        onSortClick={handleSortClick}
        scoreBy={scoreBy}
        onScoreByClick={handleScoreByClick}
        isByAccountDisabled={isByAccountDisabled}
      />
    </SubPagesLayout>
  );
}
