export const navigation = [
  {
    title: 'Leaderboard',
    url: '/instructor/insights/leaderboard/points',
    children: [
      {
        title: 'Points',
        url: '/instructor/insights/leaderboard/points',
      },
      {
        title: 'Accuracy',
        url: '/instructor/insights/leaderboard/accuracy',
      },
      {
        title: 'Average time',
        url: '/instructor/insights/leaderboard/average-time',
      },
    ]
  },
  {
    title: 'Decision Insights',
    url: '/instructor/insights/decision-insights/overall',
    children: [
      {
        title: 'Overall',
        url: '/instructor/insights/decision-insights/overall',
      },
      {
        title: 'Classification',
        url: '/instructor/insights/decision-insights/classification/1',
      },
      {
        title: 'Normal balance',
        url: '/instructor/insights/decision-insights/normal/1',
      },
      {
        title: 'Double Entry',
        url: '/instructor/insights/decision-insights/balanced/1',
      }
    ]
  },
  {
    title: 'Progress & Scores',
    url: '/instructor/insights/progress-scores/progress',
    children: [
      {
        title: 'Progress',
        url: '/instructor/insights/progress-scores/progress',
      }
    ]
  }
];