import { useState } from 'react';
import './styles.css';
import TableControls from './controls';

export default function Table({ headers, rows }) {
  const [sortConfig, setSortConfig] = useState({ key: 0, direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const sortedRows = [...rows].sort((a, b) => {
    if (sortConfig.key === null) return 0;
    const aValue = a[sortConfig.key]?.toLowerCase() || '';
    const bValue = b[sortConfig.key]?.toLowerCase() || '';
    return sortConfig.direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
  });

  const totalRows = rows.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const paginatedRows = sortedRows.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  const handleSort = (index) => {
    setSortConfig(prev => ({
      key: index,
      direction: prev.key === index && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const handlePageChange = (direction) => {
    setCurrentPage(prev => Math.max(1, Math.min(totalPages, prev + direction)));
  };

  return (
    <div className="data-table-container">
      <table className="data-table">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th 
                key={index} 
                onClick={() => handleSort(index)}
                className={sortConfig.key === index ? `active ${sortConfig.direction}` : ''}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {paginatedRows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <TableControls
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={setRowsPerPage}
        currentPage={currentPage}
        onCurrentPageChange={setCurrentPage}
        totalRows={totalRows}
        onPageChange={handlePageChange}
        totalPages={totalPages}
      />
    </div>
  );
}
