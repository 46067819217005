
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import {
  getClassificationScoresInsights,
  getNormalScoresInsights
} from '../api';

import './styles.css';

import { navigation } from '../data';
import SubPagesLayout from '../../UI/layout/subPages';
import { LeaderboardOverall } from '../../UI/instructor/leaderboard/overall';

import { elementsQuestions } from './data';

export default function DecisionInsightsClassifyingNormal() {
  const navigate = useNavigate();

  const [filterBy, setFilterBy] = useState('Accuracy');
  const [data, setData] = useState(null);

  const filterByOptions = ['Accuracy', 'Points'];

  const { data: classificationScoresInsights } = useQuery({
    queryKey: ['classificationScoresInsightsOverall'],
    queryFn: async () => getClassificationScoresInsights()
  });

  const { data: normalScoresInsights } = useQuery({
    queryKey: ['normalScoresInsightsOverall'],
    queryFn: async () => getNormalScoresInsights()
  });

  useEffect(() => {
    if(classificationScoresInsights && normalScoresInsights) {
      formatLeaderboardData();
    }
  }, [classificationScoresInsights, normalScoresInsights, filterBy]);
    
  const calculateAverage = (data, key, prop) => {
    if (!data || data.length === 0) return 0;
    console.log('data', data);
    console.log('key', key);
    console.log('prop', prop);

    let sum = 0;
    let count = 0;

    data.forEach(record => {
      const value = record[key]?.[prop];
      if (value !== null && value !== undefined) {
        sum += value;
        count++;
      }
    });

    return count > 0 ? sum / count : 0;
  };

  const getScoreData = (scores, filterKey, prop, backgroundColor) => {
    const score = calculateAverage(scores, filterKey, prop);
      return {
        score,
        formattedScore: filterBy === 'Accuracy' ? (score * 100).toFixed(2) + '%' : score,
        backgroundColor
      };
  };

  const formatLeaderboardData = () => {
    const filterKey = filterBy === 'Accuracy' ? 'accuracy' : 'points';

    console.log('classificationScoresInsights', classificationScoresInsights);
    console.log('normalScoresInsights', normalScoresInsights);

    const wholeLeaderboardData = elementsQuestions.map(({ name, key }) => ({
      name,
      lines: [
        getScoreData(classificationScoresInsights, filterKey, key, 'rgba(85, 180, 188, 0.8)'),
        getScoreData(normalScoresInsights, filterKey, key, '#FDD38A')
      ]
    }));

    setData(wholeLeaderboardData);
  }

  const handlePrevButtonClick = () => {
    navigate('/insights/decision-insights/overall/');
  }

  const handleNextButtonClick = () => {
    navigate('/insights/decision-insights/double-entry/');
  }

  const handleFilterClick = (selectedFilterBy) => {
    setFilterBy(selectedFilterBy);
  }

  const maxScore = data 
    ? data.reduce((max, item) => {
        const lineMax = item.lines.reduce((lineMax, line) => (line.score > lineMax ? line.score : lineMax), -Infinity);
        return lineMax > max ? lineMax : max;
      }, -Infinity)
    : 0;

  const labels = [{name: 'Classification', color: 'rgba(85, 180, 188, 0.8)'}, {name: 'Normal', color: '#FDD38A'}];

  return (
    <SubPagesLayout
      navigation={navigation}
      onFirstButtonClick={handlePrevButtonClick}
      onSecondButtonClick={handleNextButtonClick}
      currentPage={1}
      pagesQuantity={1}
      contentClassName="decision-insights-content-container"
    >
      <h1>Insights | Classifying & Normal Balances</h1>
      <h2>Classifying Accounts & Their Normal Balances</h2>
      <p>The chart below is an indicator of your grasp of account classifications and their normal balances. You can filter the data to view it based on points, which reflect both speed and accuracy, or focus solely on accuracy.</p>
      <LeaderboardOverall
        title="Performance"
        data={data}
        filterBy={filterBy}
        filterByOptions={filterByOptions}
        onFilterByClick={handleFilterClick}
        maxScore={maxScore}
        labels={labels}
      />
      <p><span className="red"><b>Note:</b></span> The chart will continuously update until the deadline has passed on all assignments.</p>
    </SubPagesLayout>
  );
}
