import Pagination from './pagination';
import AnswerOptions from './answerOptions';

import './styles.css';

export default function TransactionsDecisions({ 
  transactions,
  activeTransaction,
  answerOptions,
  selectedAnswerOption,
  answerStatus,
  answerPoints,
  transactionToShow,
  correctlyAnsweredTransactions,
  onAnswerOptionClick,
  onSubmitButtonClick,
  isSubmitButtonShown,
  isSubmitButtonLoading,
  onNextButtonClick,
  shouldFirstQuestionBeShown
}) {  
  const questionWasAnswered = correctlyAnsweredTransactions.find(correctlyAnsweredTransaction => correctlyAnsweredTransaction?.id === transactions[activeTransaction]?.id);
  const optionsDisabled = !!questionWasAnswered;
  const currentSelectedOption = (optionsDisabled && !transactionToShow) ? questionWasAnswered.answer : selectedAnswerOption;
  const currentAnswerStatus = (optionsDisabled && !transactionToShow) ? 'correct' : answerStatus;

  const pointsText = answerPoints >= 90
    ? 'Mastery!'
    : answerPoints >= 70
      ? 'Amazing!'
      : answerPoints >= 50
        ? 'Impressive!'
        : 'Not bad!';

  return (
    <div className="transactions-decisions-wrapper">
      <Pagination 
        transactions={transactions}
        activeTransaction={activeTransaction}
      />
      <div className="transaction-description-question-wrapper">
        <p inert="inert" className={`transaction-description ${shouldFirstQuestionBeShown === 0 ? 'first-decision' : ''}`}><span>{transactions[activeTransaction]?.description}</span></p>
        
        {
          answerStatus === 'correct' &&
            <div className="block-item-points">
              <span>{pointsText}</span>
              <span>+ {Math.floor(answerPoints)}</span>
            </div>
        }

        {
          shouldFirstQuestionBeShown && 
            <p className="transaction-question">
              <span>How would the above impact the company’s net profits for the month?</span>
            </p>
        }
      </div>

      <AnswerOptions
        options={answerOptions} 
        selectedOption={currentSelectedOption}
        answerStatus={currentAnswerStatus}
        optionsDisabled={optionsDisabled}
        onOptionClick={onAnswerOptionClick}
      />

      {
        isSubmitButtonShown &&
          <button 
            className={`button-navi ${isSubmitButtonLoading ? 'loading' : ''}`}
            disabled={!selectedAnswerOption}
            onClick={onSubmitButtonClick}
          >
            {!isSubmitButtonLoading && 'Submit'}
          </button>
      }
      {
        !isSubmitButtonShown &&
          <button className="button-navi button-next" onClick={onNextButtonClick}>Next</button>
      }
    </div>
  );
}
