import './styles.css';

export default function InstructorAccountItemRadioButton({ id, label, isChecked, onCheck }) {
  return (
    <div className="account-item-radio">
      <input 
        type="radio" 
        id={id} 
        checked={isChecked} 
        onChange={onCheck}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}