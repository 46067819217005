export const games = [
  {
    name: 'Account Classification',
    active: true
  },
  {
    name: 'Normal Balance',
    active: true
  },
  {
    name: 'Balanced Equation',
    active: true
  },
  {
    name: 'The Principles',
    active: true
  },
  {
    name: 'Accounting Cycle',
    active: false
  }
];

export const accountColumnTitles = {
  'Account Classification': [
    'Assets', 'Liabilities', 'Equity', 'Revenues', 'Expenses'
  ],
  'Normal Balance': [
    'Assets', 'Liabilities', 'Equity', 'Revenues', 'Expenses'
  ],
  'Balanced Equation': [
    'Transactions'
  ],
  'The Principles': [
    'Transactions'
  ]
};

export const balancedEquationAccountsTexting = [
  'In Level 1, students will analyze each transaction and determine whether a transaction increases or decreases Assets, Liabilities, or Equity',
  'In level 2, students will analyze each transaction to make 2 decisions: identify whether it increases or decreases balance sheet elements and determine the specific accounts involved.',
  'At this level, students will analyze each transaction to make 3 decisions: identify whether it increases or decreases balance sheet elements, determine the specific accounts involved, and decide the appropriate debits and credits.',
];