
import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import InstructorAssignmentForm from '../../../UI/instructor/settings/assignments/form';
import InstructorLevelItemsModal from '../../../UI/instructor/settings/assignments/levelItemsModal';

import { 
  navigation,
  gameCreateItemsQuestionsColumns,
  gameCreateItemsOptionsClassification,
  gameCreateItemsOptionsNormal,
  scenarioLevelsDescription
} from './data';

import {
  gamesQuery,
  coursesQuery,
  gamesItemsQuery,
  scenariosQuery,
  scenarioLevelsQuery,
  createScenario,
  createGame,
  createScenarioLevels,
  createItemQuestions,
  createItemOptions,
  scenarioAddCourse
} from './api';

import { formatScnearioLevelToFilterName } from '../../../../utils';
import SubPagesLayout from '../../../UI/layout/subPages';

export default function InstructorSettingsAddAssignments() {
  const navigate = useNavigate();
  
  const [isLoading, setIsLoading] = useState(false);
  const [gameCreatedId, setGameCreatedId] = useState(0);

  const [isModalShown, setIsModalShown] = useState(false);
  
  const [corporateScenarioId, setCorporateScenarioId] = useState(null);
  const [nonCorporateScenarioId, setNonCorporateScenarioId] = useState(null);
  
  const [entityTypeScenarioId, setEntityTypeScenarioId] = useState(null);
  const [specialAccountsScenarioId, setSpecialAccountsScenarioId] = useState(null);
  
  const [principlesCorporateScenarioId, setPrinciplesCorporateScenarioId] = useState(null);
  const [principlesNonCorporateScenarioId, setPrinciplesNonCorporateScenarioId] = useState(null);
  
  const [filterItems, setFilterItems] = useState({});
  const [gameType, setGameType] = useState();

  const gamesResponse = useQuery(gamesQuery()) || {};
  const games = gamesResponse.data;

  const coursesResponse = useQuery(coursesQuery()) || {};
  const courses = coursesResponse.data;
  
  const gamesItemsResponse = useQuery(gamesItemsQuery()) || {};
  const gamesItems = gamesItemsResponse.data;

  const { data: scenarios, refetch: refetchScenariosQuery } = useQuery(scenariosQuery());

  const { data: corporateScenarioLevels } = useQuery(scenarioLevelsQuery({ scenarioId: corporateScenarioId }));
  const { data: nonCorporateScenarioLevels } = useQuery(scenarioLevelsQuery({ scenarioId: nonCorporateScenarioId }));

  const { data: principlesCorporateScenarioLevels } = useQuery(scenarioLevelsQuery({ scenarioId: principlesCorporateScenarioId }));
  const { data: principlesNonCorporateScenarioLevels } = useQuery(scenarioLevelsQuery({ scenarioId: principlesNonCorporateScenarioId }));

  const [createdScenarioId, setCreatedScenarioId] = useState(0);

  const createScenarioMutation = useMutation({
    mutationFn: (data) => createScenario(data)
  });
  
  const createGameMutation = useMutation({
    mutationFn: (data) => createGame(data)
  });
  
  const createScenarioLevelsMutation = useMutation({
    mutationFn: (data) => createScenarioLevels(data)
  });
  
  const createItemQuestionsMutation = useMutation({
    mutationFn: (data) => createItemQuestions(data)
  });
  
  const scenarioAddCourseMutation = useMutation({
    mutationFn: (data) => scenarioAddCourse(data)
  });
  
  const createItemOptionsMutation = useMutation({
    mutationFn: (data) => createItemOptions(data)
  });

  useEffect(() => {
    if(games && !games.length) {
      refetchScenariosQuery();
    }
  }, [games]);

  useEffect(() => {
    if(scenarios && games) {
      const corporateScenario = scenarios.find(item => item.name === 'be-corporate');
      const nonCorporateScenario = scenarios.find(item => item.name === 'be-noncorporate');

      const entityTypeScenario = scenarios.find(item => item.name === 'entity-type');
      const specialAccountsScenario = scenarios.find(item => item.name === 'special-accounts');

      const principlesCorporateScenario = scenarios.find(item => item.name === 'principles-corporate');
      const principlesNonCorporateScenario = scenarios.find(item => item.name === 'principles-noncorporate');
  
      if(gameType === 'Balanced Equation' || !games.length) {
        setCorporateScenarioId(corporateScenario?.id || null);
        setNonCorporateScenarioId(nonCorporateScenario?.id || null);
      }
  
      if(!games.length) {
        setEntityTypeScenarioId(entityTypeScenario?.id || null);
        setSpecialAccountsScenarioId(specialAccountsScenario?.id || null);
      }
  
      if(gameType === 'The Principles' || !games.length) {
        setPrinciplesCorporateScenarioId(principlesCorporateScenario?.id || null);
        setPrinciplesNonCorporateScenarioId(principlesNonCorporateScenario?.id || null);
      }
    }
  }, [scenarios, games]);

  useEffect(() => {
    if(corporateScenarioLevels?.length && nonCorporateScenarioLevels?.length && gameType === 'Balanced Equation') {
      const filterItems = {
        'Corporate': {
          ...corporateScenarioLevels.reduce((acc, { name, be_questions }) => {
            acc[formatScnearioLevelToFilterName(name)] = be_questions.map(({ transaction_id, order, description }) => ({
              id: transaction_id,
              order,
              text: description,
              column: 'Transactions'
            }));
            return acc;
          }, {})
        },
        'Non-Corporate': {
          ...nonCorporateScenarioLevels.reduce((acc, { name, be_questions }) => {
            acc[formatScnearioLevelToFilterName(name)] = be_questions.map(({ transaction_id, order, description }) => ({
              id: transaction_id,
              order,
              text: description,
              column: 'Transactions'
            }));
            return acc;
          }, {})
        },
      };

      setFilterItems(filterItems);
    }
  }, [corporateScenarioLevels, nonCorporateScenarioLevels, gameType]);

  useEffect(() => {
    if(principlesCorporateScenarioLevels?.length && principlesNonCorporateScenarioLevels?.length && gameType === 'The Principles') {
      const filterItems = {
        'Corporate': {
          ...principlesCorporateScenarioLevels.reduce((acc, { name, transactions }) => {
            acc[formatScnearioLevelToFilterName(name)] = transactions.map(({ id, order, description, transaction_id }) => ({
              id,
              order,
              text: description,
              column: 'Transactions',
              accountItemId: transaction_id
            }));
            return acc;
          }, {})
        },
        'Non-Corporate': {
          ...principlesNonCorporateScenarioLevels.reduce((acc, { name, transactions }) => {
            acc[formatScnearioLevelToFilterName(name)] = transactions.map(({ id, order, description, transaction_id }) => ({
              id,
              order,
              text: description,
              column: 'Transactions',
              accountItemId: transaction_id
            }));
            return acc;
          }, {})
        },
      };

      setFilterItems(filterItems);
    }
  }, [principlesCorporateScenarioLevels, principlesNonCorporateScenarioLevels, gameType]);

  const handleAddGame = async ({
    name,
    description,
    startTime,
    endTime,
    gameType
  }) => {
    setGameType(gameType);
    setIsLoading(true);
    let resultCreateGame;

    const courseIds = courses.map(course => course.id);
    const resultCreateScenario = await createScenarioMutation.mutateAsync({name, courses: courseIds});
    if(resultCreateScenario?.id) {
      if(!games.length) {
        await scenarioAddCourseMutation.mutateAsync({scenarioId: corporateScenarioId, courseId: courseIds[0]});
        await scenarioAddCourseMutation.mutateAsync({scenarioId: nonCorporateScenarioId, courseId: courseIds[0]});
        await scenarioAddCourseMutation.mutateAsync({scenarioId: entityTypeScenarioId, courseId: courseIds[0]});
        await scenarioAddCourseMutation.mutateAsync({scenarioId: specialAccountsScenarioId, courseId: courseIds[0]});
        await scenarioAddCourseMutation.mutateAsync({scenarioId: principlesCorporateScenarioId, courseId: courseIds[0]});
        await scenarioAddCourseMutation.mutateAsync({scenarioId: principlesNonCorporateScenarioId, courseId: courseIds[0]});
      }

      setCreatedScenarioId(resultCreateScenario?.id);
      resultCreateGame = await createGameMutation.mutateAsync({
        name,
        description,
        startTime,
        endTime,
        gameType,
        scenarioId: resultCreateScenario.id,
        courseId: courses[0]?.id
      }).catch((error) => {
        console.error('Caught error with mutateAsync:', error);
      });
      if (createGameMutation.isError) {
        console.error('Error:', createGameMutation.error);
      }
      setGameCreatedId(resultCreateGame.id);
      if(gameType === 'Balanced Equation' || gameType === 'The Principles') {
        setIsModalShown(true);
        refetchScenariosQuery();
      } else {
        const resultCreateScenarioLevels = await createScenarioLevelsMutation.mutateAsync({ 
          name: `${courses[0]?.name}1`,
          levelNumber: 1,
          scenarios: [resultCreateScenario?.id],
          description: scenarioLevelsDescription[gameType][0]
        });
        const gameTypeShortened = gameType.includes('Account Classification') 
          ? 'classification'
          : gameType.includes('Normal Balance')
            ? 'normal'
            : '';
        if(resultCreateScenarioLevels?.id) {
          try {
            for (const [index, gameCreateItemQuestion] of gameCreateItemsQuestionsColumns.entries()) {
              const matchedGamesItemQuestion = gamesItems.find((gamesItem) => gamesItem.name === gameCreateItemQuestion);
              if(matchedGamesItemQuestion) {
                await createItemQuestionsMutation.mutateAsync({
                  gameType: gameTypeShortened,
                  order: matchedGamesItemQuestion.order || index + 1,
                  accountItemId: matchedGamesItemQuestion.id,
                  scenarioLevelId: resultCreateScenarioLevels.id
                });
              }
            }          
          } catch (error) {
            console.log('Some mutations failed:', error);
          } finally {
            const gameCreateItemsOptions = gameTypeShortened === 'classification'
              ? gameCreateItemsOptionsClassification
              : gameCreateItemsOptionsNormal;
            
            try {
              for (const [index, gameCreateItemOption] of gameCreateItemsOptions.entries()) {
                const matchedGamesItemOption = gamesItems.find((gamesItem) => gamesItem.name === gameCreateItemOption);
                if(matchedGamesItemOption) {
                  await createItemOptionsMutation.mutateAsync({
                    gameType: gameTypeShortened,
                    order: matchedGamesItemOption.order || index + 1,
                    accountItemId: matchedGamesItemOption.id,
                    scenarioLevelId: resultCreateScenarioLevels.id
                  });
                }
              }          
            } catch (error) {
              console.log('Some mutations failed:', error);
            }
          }
        }
      }
    }
    setIsLoading(false);

    if(resultCreateGame?.id && (gameType !== 'Balanced Equation' && gameType !== 'The Principles')) {
      navigate(`/instructor/settings/assignments/edit/${resultCreateGame.id}`);
    }
  }

  const handleCloseButtonClick = () => {
    navigate('/instructor/settings/assignments');
  }

  const handleModalCloseButtonClick = () => {
    navigate(`/instructor/settings/assignments/edit/${gameCreatedId}`);
  }

  const handleBalancedPrinciplesAccontSelectSaveButtonClick = async (selectedAccountItems, selectedFilter, accountItemsSavedCallback, gameType) => {
    setIsLoading(true);
    const resultCreateScenarioLevels = await createScenarioLevelsMutation.mutateAsync({ 
      name: `${courses[0]?.name}1`,
      levelNumber: 1,
      scenarios: [createdScenarioId],
      description: scenarioLevelsDescription[gameType][0]
    });
    
    if(resultCreateScenarioLevels?.id) {
      try {
        for (const [index, gameCreateItemQuestion] of selectedAccountItems.entries()) {
          let gamesItemQuestion;
          gamesItemQuestion = gameCreateItemQuestion;
          const gameTypeShortened = gameType.includes('Balanced Equation') 
          ? 'equation'
          : gameType.includes('The Principles')
            ? 'principles'
            : '';

          if(gamesItemQuestion) {
            await createItemQuestionsMutation.mutateAsync({
              gameType: gameTypeShortened,
              order: gamesItemQuestion.order || index + 1,
              accountItemId: gamesItemQuestion.accountItemId || gamesItemQuestion.id,
              scenarioLevelId: resultCreateScenarioLevels.id
            });
          }
        }          
      } catch (error) {
        console.log('Some mutations failed:', error);
      } finally {
        if(gameType === 'Balanced Equation') {
          const balanceEquationScenarioLevels = [...corporateScenarioLevels, ...nonCorporateScenarioLevels];
          const filterScenarioLevel = balanceEquationScenarioLevels.find(level => 
            level.name.toLowerCase().replace(/\s+/g, '-') === selectedFilter.toLowerCase().replace(/\s+/g, '-')
          );

          let filterScenarioOptions = filterScenarioLevel.be_options.filter(option => option.order >= 1 && option.order <= 5);
          
          try {
            for (const [index, gameCreateItemOption] of filterScenarioOptions.entries()) {
              await createItemOptionsMutation.mutateAsync({
                gameType: 'equation',
                order: gameCreateItemOption.order || index + 1,
                accountItemId: gameCreateItemOption.account_item.id,
                scenarioLevelId: resultCreateScenarioLevels.id
              });
            }          
          } catch (error) {
            console.log('Some mutations failed:', error);
          }
        }
      }
    }
    
    accountItemsSavedCallback();
    setIsLoading(false);
    navigate(`/instructor/settings/assignments/edit/${gameCreatedId}`);
  }

  return (
    <SubPagesLayout
      navigation={navigation}
      contentClassName="adjustment-content-container"
      showPagination={false}
      secondButtonName="Close"
      onSecondButtonClick={handleCloseButtonClick}
    >
      <InstructorAssignmentForm
        gamesCount={games?.length}
        onSave={handleAddGame}
        isButtonLoading={isLoading}
      />

      {isModalShown && (
        <InstructorLevelItemsModal
          scenarioLevelNumber={1}
          onCloseButtonClick={handleModalCloseButtonClick}
          onSaveButtonClick={handleBalancedPrinciplesAccontSelectSaveButtonClick}
          isButtonLoading={isLoading}
          gameType={gameType}
          filterItems={filterItems}
        />
      )}
    </SubPagesLayout>
  );
}
