import './styles.css';

import gameLogoClassification from './images/game-logo-classification.png';
import gameLogoBalancedEquation from './images/game-logo-balanced-equation.png';
import gameLogoNormalBalance from './images/game-logo-normal-balance.png';
import gameLogoThePrinciples from './images/game-logo-the-principles.png';

import { gameTypeMappings } from '../../../vars';
import AssignmentItem from './item';

export default function AssignmentType({ gameType, games, onGameDeletionConfirm, sessions, scores }) {
  const gameTypeImages = {
    'Account Classification': gameLogoClassification,
    'Balanced Equation': gameLogoBalancedEquation,
    'Normal Balance': gameLogoNormalBalance,
    'The Principles': gameLogoThePrinciples,
  };

  const imageSrc = gameTypeImages[gameType];

  const gameTypeTitles = {
    'Account Classification': 'Classification',
    'Normal Balance': 'Normal Balance',
  };

  const gameTypeShortened = Object.entries(gameTypeMappings).find(([key]) => gameType?.includes(key))?.[1] || '';

  return (
    <div className={`games-assignment-type ${gameTypeShortened}`}>
      <div className="games-assignment-type-heading">
        {imageSrc && <img src={imageSrc} alt={gameType} className="game-logo" />}
        <h3>{gameTypeTitles[gameType] || gameType}</h3>
      </div>
      {
        games.map((game, index) => {
          const currentGameSessions = sessions.filter(session => session.game === game.id);

          const latestCompletedSession = sessions
            .filter(session => session.game === game.id && session.completed)
            .reduce((maxSession, session) => (session.id > (maxSession?.id || 0) ? session : maxSession), null);
            
          const currentGameScore = scores?.filter(score => score.game === game.id && score.game_session === latestCompletedSession?.id).reduce((sum, score) => sum + score.total_points, 0);
          
          return(
            <AssignmentItem 
              key={index}
              game={game}
              onGameDeletionConfirm={onGameDeletionConfirm}
              currentGameSessions={currentGameSessions}
              currentGameScore={currentGameScore}
            />
          )
        })
      }
    </div>
  );
}
