const authService = {
  setToken: (value) => {
    localStorage.setItem('token', value);
  },
  
  getToken: () => {
    return localStorage.getItem('token');
  },
  
  clearToken: () => {
    localStorage.removeItem('token');
  },
  
  setUser: (userData) => {
    localStorage.setItem('user', JSON.stringify(userData));
  },
  
  getUser: () => {
    const userData = localStorage.getItem('user');
    return userData ? JSON.parse(userData) : null;
  },
  
  clearUser: () => {
    localStorage.removeItem('user');
  },
  
  setSoundStatus: (value) => {
    localStorage.setItem('sound', value);
  },

  getSoundStatus: () => {
    return localStorage.getItem('sound');
  },
  
  clearSoundStatus: () => {
    localStorage.removeItem('sound');
  },
  
  setCurrentGameId: (value) => {
    localStorage.setItem('gameId', value);
  },
  
  setCurrentClassificationGameId: (value) => {
    localStorage.setItem('classificationGameId', value);
  },
  
  setCurrentNormalGameId: (value) => {
    localStorage.setItem('normalGameId', value);
  },
  
  setCurrentBalancedGameId: (value) => {
    localStorage.setItem('balancedGameId', value);
  },
  
  setCurrentThePrinciplesGameId: (value) => {
    localStorage.setItem('thePrinciplesGameId', value);
  },

  getCurrentGameId: () => {
    return localStorage.getItem('gameId');
  },

  getCurrentClassificationGameId: () => {
    return localStorage.getItem('classificationGameId');
  },

  getCurrentNormalGameId: () => {
    return localStorage.getItem('normalGameId');
  },

  getCurrentBalancedGameId: () => {
    return localStorage.getItem('balancedGameId');
  },

  getCurrentThePrinciplesGameId: () => {
    return localStorage.getItem('thePrinciplesGameId');
  },
  
  clearCurrentGameId: () => {
    localStorage.removeItem('gameId');
  },
  
  clearCurrentClassificationGameId: () => {
    localStorage.removeItem('classificationGameId');
  },
  
  clearCurrentNormalGameId: () => {
    localStorage.removeItem('normalGameId');
  },
  
  clearCurrentBalancedGameId: () => {
    localStorage.removeItem('balancedGameId');
  },
  
  clearCurrentThePrinciplesGameId: () => {
    localStorage.removeItem('thePrinciplesGameId');
  },

  setTransitionGameType: (value) => {
    localStorage.setItem('gameType', value);
  },

  getTransitionGameType: () => {
    return localStorage.getItem('gameType');
  },

  clearTransitionGameType: () => {
    return localStorage.removeItem('gameType');
  },
  
  setCurrentScenarioId: (value) => {
    localStorage.setItem('scenarioId', value);
  },

  getCurrentScenarioId: () => {
    return localStorage.getItem('scenarioId');
  },
  
  clearCurrentScenarioId: () => {
    localStorage.removeItem('scenarioId');
  },

  setInactivityTime: (value) => {
    localStorage.setItem('inactivityTime', value);
  },

  getInactivityTime: () => {
    return localStorage.getItem('inactivityTime');
  },
  
  clearInactivityTime: () => {
    localStorage.removeItem('inactivityTime');
  },
  
  setGuidedPlay: (value) => {
    localStorage.setItem('guidedPlay', JSON.stringify(value));
  },

  getGuidedPlay: () => {
    const value = localStorage.getItem('guidedPlay');
    return value ? JSON.parse(value) : false;
  },
  
  clearGuidedPlay: () => {
    localStorage.removeItem('guidedPlay');
  },
};

export default authService;
